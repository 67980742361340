$font-gotham-light: "gothamLight";
$font-gotham-book: "gothamBook";
$font-gotham-medium: "gothamMedium";
$font-gotham-bold: "gothamBold";
$font-merriweather-bold: "MerriweatherBold";
$font-password: "password";

$font-color: #707070;
$btn-color-disable: #f5f5f5;
$btn-color-dange: #1f0b0c;
$btn-color-blueLight: #59d7d7;
$btn-color-blueDark: #41b1cd;
$main-backgound: "";

html,
body {
  // height: 100vh;
  // background-color: #fafbfbfb;
  /* For browsers that do not support gradients */
  // background: #12B6DF;
  // background: -webkit-linear-gradient(270deg, #12B6DF 0%, #F8EFE4 49.48%);
  /* For Safari 5.1 to 6.0 */
  // background: -o-linear-gradient(180deg, #12B6DF 0%, #F8EFE4 49.48%);
  /* For Opera 11.1 to 12.0 */
  // background: -moz-linear-gradient(180deg, #12B6DF 0%, #F8EFE4 49.48%);
  /* For Firefox 3.6 to 15 */
  // background: linear-gradient(180deg, #12b6df 0%, #f8efe4 49.48%);
  /* Standard syntax (must be last) */
}

/*!****************
  DESKTOP CSS 
******************/

@media (min-width: 767px) {
  .fixed-top {
    position: absolute !important;
  }
  .header-one-container{
    width: 50%;
    margin: auto;
  }
 .event-container{
  .header-event{
  
   .header-two-container{
    margin: auto;
    width: 50%;
    background: none;
    .container{
      .header-row{
        label{
          color: #ffffff;
        }
      }
    }
   }
  }
  .scroll-component{
    .event-desktop{
      // width: 50%;
      // margin: auto;
    }
  }
 }

 .place-container{
  .header-place{
   background: linear-gradient(180deg, #ffffff 54.68%, rgba(255, 255, 255, 0) 103.53%, rgba(245, 245, 245, 0) 100%);
   .header-two-container{
     background: none;
     margin: auto;
     width: 50%; 
    .container{
      .header-row{
        label{
          color: #ffffff;
        }
      }
    }
   }
  }
 }

  .content-component {
    margin: auto;
    width: 70%;
    position: relative;
    z-index: 2;
    transition: 500ms ease-out;
  }
.activity-card-container{
  .btn-row{

    .btn-tranparent{
      width: 50%;
     margin-left: auto;
    }
  }
}

.event-card-container{
  .btn-row{
    .btn-tranparent{
      width: 50%;
     margin-left: auto;
    }
  }
}

.room-container{
  .room-container-desktop{
   width: 50%;
   .room-checkbox{
    .btn-primary{
      font-size: 0.625rem;
    }
   
    .btn-check:checked + .btn-primary {
      font-size: 0.7rem;

    }
   }
  }
}

.room-listing-container-desktop{
  .scroll-component{
    .grid-component{
      margin: auto;
      width: 40%;
    }
  }
}

  .activity-card-container {
    .card {
      .card-img-top {
        height: 300px;
      }
    }
  }

  .promotion-container {
    .card {
      .row {
        .room-detail {
          padding: 1em;
        }
      }
    }
  }

  .service-container {
    .container {
      .header {
        text-align: center;
      }
    }
    .fixed-container {
      form {
        width: 25%;
        margin: 0 auto;
      }
    }
  }

  .ticket-container-desktop {
    margin: 0 !important;
    .scroll-component {
      .grid-container {
        width: 40%;
      }
    }
  }

 
  .check-out-container > div {
    width: 40%;
    margin: 0 auto;
    height: 80%;
    padding: 1.5em;
  }

  .profile-container {
    .header {
      text-align: center;
    }
    .fixed-container {
      .container {
        &.refer-detail{
          width: 100%;
        }
        width: 40%;
      }
    }
  }

.planing-container {
  .header {
    text-align: center;
  }
  .fixed-container {
    // width: 40% !important;
    margin: 0 auto;
    .m-0 {
      .empty-data {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          height: 70%;
        }
      }
      .h1{
        padding-top: 3em;
        display: flex;
        justify-content: center;
        color: #888daa;
        font-family: $font-gotham-medium;
      }
    }
  }
}

  .room-carousel-container{
    .swiper{
      .swiper-wrapper{

        .promotion-container{
    
          .card{
      
            .image-col{
             height: 9em  !important;
            }
          }
        }
      }
      }
}

.activity-container{
  .container-desktop{
    margin: auto;
    width: 50%;
  }
}
.promotion-page-container{
  .promotion-desktop{
    margin: auto;
    width: 50%;
      .promotion-card{
    margin: 0.7em  1em;
  }
  }

}

.planing-container{
  .fixed-container{
    .planning-desktop{
      margin: auto;
      width: 50%;
    }
  }
}

.payment-container{
  .form{
    width: 50%;
    margin: auto;  
  }
  .form-group {
    &.btn-buy-now{
      width: 50%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

}

/*!****************
   Common Component 
******************/

.loader-container{
  height: 70vh;
  justify-content: center;
  align-items: center;
  z-index: 4;
  position: relative;
  display: flex;
}

.p-skeleton {
  background-color: #adb0b4ab !important;
}
.skeleton-container {
  padding: 0.5em 0;
}

.header-image {
  position: absolute;
  height: 20em;
  top: 0em;
  left: 0;
  // box-shadow: inset 80px 0px 90px rgba(27, 26, 26, 0.59)
}

.layout-component {
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 1;
  left: 0;
  top: 0;
  background: linear-gradient(180deg, #12b6df 0%, #f8efe4 49.48%);
}

.layout-component2 {
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 1;
  left: 0;
  top: 0;
  background: #fafbfb;
}

.layout2-component {
  background: linear-gradient(180deg, #ffffff 16.68%, rgba(255, 255, 255, 0) 50.53%, rgba(245, 245, 245, 0) 100%);
  position: fixed;
  height: 5vh;
  width: 100%;
  z-index: 3;
  left: 0;
  top: 0;
}

.header-one-container {
  box-shadow: none !important;
  justify-content: center !important;
  .container {
    padding: 0em 1.5em;
    margin: 1em 1em;
    display: block !important;
    justify-content: unset !important;
    .header-row {
      align-items: center;
      height: 4em;
      .logo-container {
        position: absolute;
        text-align: start;
        padding: 0rem;
        .logo-button {
          width: 3em;
          margin: 0;
          border-radius: 100%;
          box-shadow: 0 2px 10px #5a575788;
          transition-duration: 500ms;
          &.after {
            width: 2.8em;
            box-shadow: none;
            margin: 0.3rem;
          }
        }
      }
    }
  }
  .animation {
    background-color: #ffffff !important;
    border-radius: 10em;
    padding: 0rem !important;
    margin: 0 1rem;
    display: flex;
    width: 0;
    height: 2em;
    // transition-duration: 500ms;
    transition: width 300ms ease-in, height 300ms ease-in, margin 200ms ease-out;
    &.after {
      height: 3.2em;
      margin: 0%;
      border-radius: 2em;
      width: 100%;
    }
    .iconify{
      color: darkgray;
      
          }
    .iconify:hover{
      color: rgb(119, 162, 193);
    }
  }
  label {
    font-family: $font-gotham-bold;
    color: #a9a9a9;
    font-size: x-small;
    padding-top: 0.1rem;
  }
}

.header-two-container {
  background: linear-gradient(180deg, #ffffff 54.68%, rgba(255, 255, 255, 0) 103.53%, rgba(245, 245, 245, 0) 100%);
  // height: 15em;
  padding: 2rem 0.5rem !important;
  box-shadow: none !important;
  z-index: 3;
  padding-bottom: 1em;
  align-items: start !important;
  .container {
    display: block !important;
    justify-content: unset !important;
    .header-row {
      align-items: center;
      padding-top: 1em !important;
      .round-background-icon {
        background: #ffffff;
        box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
        border-radius: 50%;
        // padding: 0.3em 0.3em;
        height: 2em;
        width: 2em;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 3;
      }
      label {
        font-family: $font-merriweather-bold;
        color: #707070;
        font-size: 24px;
        padding-top: 0.1rem;
      }
    }
  }
}

.header-three-container {
  background: transparent;
  // border-radius: 1.25rem;
  // margin: 1rem;
  height: 5em;
  // padding: 0.1rem !important;
  // padding-bottom: 0 !important;
  box-shadow: none !important;
  .container {
    display: block !important;
    justify-content: unset !important;
    .header-row {
      display: flex;
      align-items: end;
      .round-background-icon {
        background: #ffffff;
        box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
        border-radius: 50%;
        padding: 0.3em 0.2em;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .badge {
        padding: 0.5em 1.5em;
        background: rgba(18, 18, 18, 0.2);
        backdrop-filter: blur(5px);
        border-radius: 0.6rem;
        label {
          // font-family: $font-gotham-book;
          font-size: large;
        }
      }
    }
  }
}

.header-four-container {
  box-shadow: none !important;
  .container {
    display: block !important;
    padding: 0;
    justify-content: unset !important ;
    .header-row {
      .round-background-icon {
        box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
        border-radius: 1em;
        padding: 0.1em 0.4em;
      }
      label {
        font-family: $font-gotham-medium;
        font-size: 1rem;
        color: #2a2d3c;
      }
      .help {
        font-family: $font-gotham-medium;
        font-size: 0.85rem;
        color: #707070;
      }
    }
  }
}

.header-five-container {
  background: #ffffff;
  height: 5em;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25) !important;
  // border-radius: 2em 2em 0em 0em;
  .container {
    display: block !important;
    justify-content: unset !important;
    .header-row {
      align-items: center;
      // padding-top: 1em !important;
      label {
        font-family: $font-gotham-bold;
        color: #707070;
        font-size: 20px;
        padding-top: 0.1rem;
      }
      .save-btt {
        font-family: $font-gotham-medium;
        color: #707070;
        font-size: 1em;
         border: none;
         background: none;
      }
    }
  }
}


.navbar-container {
  padding: 0.8em 0em 1em 0em !important;
  background: white;
  label {
    font-family: $font-merriweather-bold;
    color: #a9a9a9;
    font-size: x-small;
    padding-top: 0.1rem;
  }
}

.content-component {
  position: relative;
  z-index: 2;
  transition: 500ms ease-out;
}

.scroll-component {
  // overflow: hidden;
  position: relative;
  min-height: 100vh;
  border-radius: 1em 1em 0 0;
  background-color: #fafbfbfb;
  padding: 2em 0.3em 5em 0.3em;
  margin-top: 8rem;
  animation: fadeInUp 500ms ease-in-out;
  .grid-component{
    overflow: hidden;
  }
}
.scroll-component-desktop {
  position: relative;
  min-height: 100vh;
  border-radius: 1em 1em 0 0;
  background-color: #fafbfbfb;
  padding: 2em 0.3em 5em 0.3em;
  // margin-top: 8rem;
  margin: 8em 25em 0em 25em;
  animation: fadeInUp 500ms ease-in-out;
}

.full-modal-box-container {
  display: flex !important;
  align-items: center;
  z-index: 9999 !important;
  // top: unset !important;
  .modal-dialog {
    width: 100%;
    // height: 100%;
  }
}
.modal-backdrop{
  // z-index: 9998!important;
  max-height: 100%;
}


.swiper {
  display: flex;
  .swiper-wrapper {
    display: flex;
    .banner-image {
      border-radius: 1em;
    }
  }
}

.fixed-bottom {
  z-index: 9997 !important;
}

/*!****************
      Element
******************/

.form-group {
  // font-family: $font-gotham-book;
  &.gender{
    margin: 1rem 0;
    margin-bottom: 0.1rem;
    color: #d6d6d6;
    font-family: "gothamBook";
    font-weight: bolder;
    border: 1px solid #0000002b;
    border-radius: 0.5rem;
    padding: 0.4rem 0.8em;
    padding-bottom: 0.7rem;
    .form-label{
      margin: 0;
      line-height: 1em;
      color: #d6d6d6 !important;
      font-size: 11px;
    }
    select{
      background: transparent;
      border: none;
      font-family: "gothamBook";
      color: #303345;
      font-weight: bolder;
      padding: 0;
      width: 100%;
    }
    select:focus{
      outline: none;
    }
  }
  &.login-field {
    input {
      border: none;
      border-bottom: 0.1rem solid #d6d6d6;
      border-radius: 0;
    }
    .form-notch {
      display: none !important;
    }
    input:focus {
      outline: 0 !important;
      color: #303345;
      // border-bottom: 0.1rem solid blue;
    }

    input::placeholder {
      color: #d6d6d6;
    }
    .form-outline {
      .form-label {
        font-family: $font-gotham-book;
        font-size: 0.9rem;
        color: #d6d6d6 !important;
      }
      .form-control:active {
        z-index: -1;
      }
    }

    input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: red;
    }

    input::-ms-input-placeholder {
      /* Microsoft Edge */
      color: red;
    }
    &.error-border {
      border: none;
    }
  }

  &.edit-field {
    input {
      border: none;
      border-radius: 0;
    }
    .form-notch {
      display: none !important;
    }
    input:focus {
      outline: 0 !important;
      color: #303345;
      // border-bottom: 0.1rem solid blue;
    }

    input::placeholder {
      color: #d6d6d6;
    }
    .form-label {
      font-family: $font-gotham-book;
      font-size: 0.9rem;
      color: #d6d6d6 !important;
    }
    input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: red;
    }

    input::-ms-input-placeholder {
      /* Microsoft Edge */
      color: red;
    }
    &.error-border {
      border: none;
    }
  }
  &.service-field {
    margin: 1rem 0;
    margin-bottom: 0.1rem;
    color: #d6d6d6;
    font-family: $font-gotham-book;
    font-weight: bolder;
    border: 1px solid #0000002b;
    border-radius: 0.5rem;
    padding: 0.8rem;
    align-items: start;
    // display: flex;
    input::-webkit-date-and-time-value {
      margin-top: 0px;
      text-align: start;
      width: 100%;
    }
    .form-outline {
      .form-label {
        color: #d6d6d6 !important;
        font-size: 14px;
      }
      .form-control {
        color: #303345;
        font-weight: bolder;
        padding: 0;
        padding-top: 0.2rem;
      }
      .form-control:disabled{
        background-color: transparent !important;
      }
    }
    .form-notch {
      display: none !important;
    }
  }
  &.contact-field {
    margin: 1rem 0;
    margin-bottom: 0.1rem;
    color: #d6d6d6;
    font-family: $font-gotham-book;
    font-weight: bolder;
    border: 1px solid #0000002b;
    border-radius: 0.5rem;
    padding: 0.8rem;
    .form-outline {
      .form-label {
        color: #888daa;
        font-size: 14px;
      }
      .form-control {
        color: #303345;
        font-weight: bolder;
        padding: 0;
        padding-top: 0.3rem;
      }
      input:focus + label{
        color: #888daa;
      }
    }
    .form-notch {
      display: none !important;
    }
  }
  &.error-message {
    color: red;
    font-size: 0.75rem;
  }

  &.input-search {
    background: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2em;
    margin: 0em 1em;
    top: -3.5em;
    position: relative;
    align-items: center;
    .input-col {
      padding: 0.5em 1.5em;
      input {
        border: none;
        outline: none;
      }
    }
  }
  &.btn-see-more {
    button {
      box-shadow: 0px -35px 20px rgba(248, 248, 248, 0.782) !important;
      font-family: $font-gotham-medium;
      // background: transparent;
      background: transparent;
      box-shadow: none;
      color: #4d8ae7;
      text-transform: capitalize;
      font-size: 0.75rem;
      padding: 0.4em;
    }
    button:active {
      box-shadow: none;
    }
  }
  &.btn-read-more {
    button {
      height: 2.86em;
      font-family: $font-gotham-medium;
      background: transparent;
      border: 0.01em solid #707070;
      border-radius: 0.5em;
      box-shadow: none;
      color: #707070;
      text-transform: uppercase;
      font-size: 0.85rem;
      padding: 0.4em;
    }
  }
  &.btn-buy-now {
    margin: 1.5rem 0;
    ._label{
      &.--title{
        font-size: 1.2rem;
        text-align: center;
        font-family: $font-gotham-medium;
      }
      &.--price{
        font-size: 1.5rem;
        text-align: center;
        color: rgb(36, 221, 19);
        font-family: $font-gotham-medium;
        
      }
    }
    button {
      margin-top: 1em;
      font-family: $font-gotham-medium;
      background: $btn-color-blueLight;
      border: none;
      height: 3em;
      border-radius: 0.5em;
      box-shadow: none;
      color: #f5f5f5;
      text-transform: capitalize;
      font-size: 1rem;
      padding: 0.7em;
      box-shadow: 0px 2px 4px rgba(131, 131, 131, 0.25);
    }
    .cancel {
      font-family: $font-gotham-medium;
      background: none;
      border: none;
      height: 3em;
      border-radius: 0.5em;
      box-shadow: none;
      color: #707070;
      text-transform: capitalize;
      font-size: 0.8rem;
      padding: 0.7em;
    }
  }
  &.btn-package {
    margin: 0.5rem 0;
    button {
      font-family: $font-gotham-bold;
      font-size: 0.875rem;
      background: #ffffff;
      border: 0.1em solid #888daa;
      border-radius: 0.3em;
      box-shadow: none;
      color: #888daa;
      text-transform: capitalize;
      font-size: 0.875rem;
      width: auto;
      padding: 0.2rem 0.5rem;
      // box-shadow: 0px 2px 4px rgba(131, 131, 131, 0.25);
    }
  }
  &.btn-select {
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      background-color: $btn-color-blueDark;
      text-transform: capitalize;
      font-size: 0.875rem;
      box-shadow: none;
      font-weight: 600;
      border-radius: 0.5rem;
      padding: 0.5rem 0.8rem;
    }
  }
  // &.error-border {
  //   border: 1px solid #ea3943;
  // }
}

.booking-calander {
  font-size: 12px;
  padding: 2em;
  .checkout-container {
    display: flex;
    padding: 0;
    label {
      width: auto;
    }
    .check-in-label-col {
      display: grid;
      font-family: $font-gotham-medium;
      justify-content: end;
      label {
        font-size: 0.875rem;
        padding: 0.5em 1em;
        background: #e9f5ff;
        border-radius: 8px;
      }
      span {
        text-align: center;
      }
    }
    .label-col {
      margin-top: 0.5em;
      display: flex;
      justify-content: center;
    }
    .check-out-label-col {
      display: grid;
      font-family: $font-gotham-medium;
      justify-content: start;
      label {
        font-size: 0.875rem;
        padding: 0.5em 1em;
        background: #e9f5ff;
        border-radius: 8px;
      }
      span {
        text-align: center;
      }
    }
  }
  .p-calendar {
    padding-top: 1em;
    .p-datepicker {
      .p-datepicker-group-container {
        .p-datepicker-group {
          .p-datepicker-header {
            .button {
              width: 2rem;
              height: 1rem;
            }
            .p-datepicker-title {
              .p-link {
                font-size: 0.85em !important;
              }
            }
          }
          .p-datepicker-calendar-container {
            .p-datepicker-calendar {
              thead {
                tr {
                  th {
                    text-align: center;
                  }
                }
              }
              tbody{
                span{
                  &.p-disabled{
                    color: #b7b7b7;
                    text-decoration: line-through;
                  }
                  font-family: $font-gotham-medium;
                }
                
              }
              font-size: small;
            }
          }
        }
      }
    }
  }
}

.btn-scroll-top-container {
  .round-background-icon {
    // background: #FFFFFF;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    margin: 1em;
    margin-bottom: 6em;
    width: 2.5em;
    height: 2.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
  }
}

.badge-row {
  margin: 0;
  .badge {
    text-align: center;
    color: #707070;
    // background: rgba(67, 67, 67, 0.1);
    margin: 0em 0.5em;
    width: auto;
    label {
      padding: 5px 10px;
      color: #707070;
      font-size: 0.75rem;
    }
    &.bg-primary {
      padding: 0.5em 1em;
      // font-family: $font-gotham-book;
      width: auto;
      margin-right: 0.3rem;
      margin-top: 0.4rem;
      margin-bottom: 0rem;
      font-size: 0.75rem;
      background: rgba(67, 67, 67, 0.1) !important;
      color: #707070 !important;
    }
  }
  .badge:first-child {
    margin-left: 0 !important;
  }
}

.container-title {
  margin-top: 1.5rem;
  margin-bottom: 0.8rem;
  label {
    display: flex;
    align-items: center;
    font-family: $font-gotham-bold;
    color: #303345;
    font-size: 1.25rem;
    div {
      margin-right: 0.3rem;
      background: #59d7d7;
      height: 1.2em;
      width: 0.3em;
      border-radius: 0.5em;
    }
  }
  span {
    color: #0267ff;
    font-size: 0.75rem;
  }
}

.container-subtitle {
  margin-top: 1rem;
  margin-bottom: 0.3rem;
  .event {
    white-space: nowrap;
    width: 17em;
    overflow: hidden;
    text-overflow: ellipsis;
    align-items: center;
    // font-family: $font-gotham-bold;
    color: #303345;
    font-size: 1rem;
  }
  .date-range {
    font-size: 0.75rem;
    display: flex;
    align-items: end;
    justify-content: end;
    font-weight: bold;
    // font-family: $font-gotham-book;
    .rmdp-container {
      input {
        border: none;
        font-weight: normal;
        border-radius: 5px;
        height: 22px;
        margin: 1px 0;
        padding: 1em;
        width: 15em;
        color: #707070;
        font-size: medium;
        background: none;
      }
      input::placeholder{
        color: #707070;
      }
    }
  }
  label {
    display: flex;
    align-items: center;
    font-family: $font-gotham-medium;
    color: #303345;
    font-size: 1rem;
  }
  .detail-button {
    color: #0267ff;
    font-family: $font-gotham-book;
    font-size: 0.75rem;
    font-weight: lighter;
  }
}

.container-date-options {
  overflow: scroll;
  flex-wrap: unset !important;
  .swiper{
    .swiper-wrapper{
      .swiper-slide{
        width: auto!important;
      }
    }
  }
  &.errors-msg {
    // label {
    //   background-color: transparent;
    //   color: $btn-color-dange;
    //   border: 0.1em solid $btn-color-dange;
    // }
    // label {
    //   white-space: nowrap;
    //   background: #f5f5f5;
    //   color: #888daa;
    //   font-size: 0.75rem;
    //   border: 0.1rem solid #ea3943;
    //   // font-family: $font-gotham-book;
    //   border-radius: 10rem;
    //   box-shadow: none;
    //   padding: 0.2em 1em;
    //   font-weight: bold;
    //   text-transform: capitalize;
    //   margin: 0.5rem;
    //   margin-left: 0;
    //   width: auto;
    // }
  }
  div {
    width: auto;
    padding: 0;
  }
  input[type="radio"]:checked + label {
    background: transparent;
    color: #41b1cd;
    border: 0.15rem solid #41b1cd;
    font-size: 0.75rem;
    // font-family: $font-gotham-book;
    border-radius: 10rem;
    box-shadow: none;
    padding: 0.2em 1em;
    // font-family: "gothamBold";
    // font-weight: bold;
    text-transform: capitalize;
    margin: 0.5rem;
    margin-left: 0;
    width: auto;
  }
  label {
    white-space: nowrap;
    background: #f5f5f5;
    color: #888daa;
    font-size: 0.75rem;
    border: 0.15rem solid #f5f5f5;
    // font-family: "gothamBold";
    // font-family: $font-gotham-book;
    border-radius: 10rem;
    box-shadow: none;
    padding: 0.2em 1em;
    // font-weight: bold;
    text-transform: capitalize;
    margin: 0.5rem;
    margin-left: 0;
    width: auto;
  }
  label:hover {
    box-shadow: none;
  }
}
.error-message {
  font-size: small;
  text-align: end;
}
.container-date-options::-webkit-scrollbar {
  display: none !important;
}

.line {
  margin-top: 1rem;
  border: 1px solid #dddddd;
}

/*!****************
   Pages Component 
******************/
.grid-container-maintenance{
  height: 100vh;
  // background: #7ba8bb;
  align-items: center;
  justify-content: center;
  display: flex;
  .page-container-maintenance{
    display: flex;
    margin: auto;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 100%;
    background: #f5f5f5;
    height: 100%;
    .container{
      .logo{
        height: 2em;
        width: 2em;
      } 
.icon{
  height: 8em;
  width: 8em;
} 
label{
  font-size: 1.5em;
  color: rgb(31, 33, 34);
  font-family: $font-gotham-bold;
  &.title{
  color: #41b1cd;
  font-size: 1em;
padding-bottom: 3em;  }
}
p{
  font-size: 0.9em;
  color: #b8b3b3;
}
}
   
  }
}

.home-container {
  overflow-x: hidden;
  padding-top: 6em;
  padding-bottom: 6em;
  .banner-header {
    background: linear-gradient(180deg, #12b6df 0%, #59d7d7 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: $font-merriweather-bold;
    font-size: 1.75rem;
    padding: 0.5em;
    line-height: 35px;
  }
  .header-container {
    align-items: center;
    margin-top: 1em;
    label{
      font-family: $font-gotham-bold;
      color: #303345;
      font-size: large;
      white-space: nowrap;
    }
  }
  .header-label-container {
    padding: 1.8em 0em;
    padding-bottom: 0em;
    // font-family: $font-gotham-book;
    font-size: 0.875rem;
    color: #12b6df;
    font-size: small;
    white-space: nowrap;
  }
  .banner-container {
    .swiper {
      display: flex;
      .swiper-wrapper {
        display: flex;
        .banner-image {
          border-radius: 1em;
          // height: 15em;
          max-height: 12em;
        }
        .banner-image-desktop {
          border-radius: 1em;
          height: 15em;
          object-fit: cover;
          // max-height: 12em;
        }
      }
    }
    .row-row{
      padding: 0.05em 0em;
      border-radius: 1em;
      .promotion-image{
      border-radius: 1.5em;
      width: 100%;
        height: 7.49em;
        object-fit: cover;
      }
    }
  }
 
  .promotion-carousel-container {
    padding: 0;
    .swiper {
      display: flex;
      padding-top: 1em;
      .swiper-wrapper {
        display: flex;
        // filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
        .promotion-container {
          // background: #f2f8fd;
          padding: 0.3rem;
          border-radius: 0.8rem;
          .fixed-container {
            bottom: 0em;
            width: 100%;
            height: 70%;
            position: absolute;
            border-radius: 1.5em 1.5em 0 0;
            background: #fafbfb;
            padding: 1em 0.5em;
          }
          .banner-image {
            border-radius: 0.6rem;
             object-fit: cover;
             height: 9em;
          }
          label {
            font-family: $font-gotham-medium;
            font-size: 1rem;
            color: #707070;
          }
         
          .label-text {
            font-family: $font-gotham-medium;
            font-size: 0.75rem;
            color: #a9a9a9;
            .rm {
              font-family: $font-gotham-medium;
              font-size: 0.75rem;
              color: #59d7d7;
            }
            .money {
              font-family: $font-gotham-medium;
              font-size: 1rem;
              color: #59d7d7;
            }
          }
        }
      }
    }
  }
  .room-carousel-container {
    padding: 0;

    .swiper {
      display: flex;
      padding-top: 1em;

      .swiper-wrapper {
        display: flex;

        // filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
        .promotion-container {
          // background: #f2f8fd;
          padding: 0.3rem;
          border-radius: 0.8rem;
          box-shadow: none;

          .card{
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

         .fixed-container {
        bottom: 0em;
        width: 100%;
        height: 100%;
        border-radius: 1.5em 1.5em 0 0;
        background: #fafbfb;
        padding: 1em 0.5em;
  }
  .image-col{
    height: 7em;
    .banner-image {
      border-radius: 0.6rem;
      height: 100%;
      object-fit: cover;
      display: flex;
  
    }
  }
  .room-detail{
    padding-left: 0;
    .title {
      padding-top: 0.1em;
      font-family: $font-gotham-medium;
      font-size: 1rem;
      color: #707070;
      display: block;
      white-space: nowrap;
      max-width: 13em;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .rating-row{
      align-items: center;
      justify-content:center ;
      .p-rating{
        gap: 0.1rem;
      }
      .label-text {
        text-align: start;
        font-family: $font-gotham-medium;
        font-size: 0.75rem;
        color: #a9a9a9;
      }
    }
    label {
      font-family: $font-gotham-medium;
      font-size: 1rem;
      color: #707070;
    }
    .label-text {
      font-family: $font-gotham-medium;
      font-size: 0.75rem;
      color: #a9a9a9;
    }
    .room-message {
      text-align: right;
      padding-right: 0.5em;
      margin-top: 0.9em;
      .label-text {
        font-family: $font-gotham-medium;
        font-size: 0.75rem;
        color: #a9a9a9;
        .rm {
          font-family: $font-gotham-medium;
          font-size: 0.75rem;
          color: #59d7d7;
        }
        .money {
          font-family: $font-gotham-medium;
          font-size: 0.9rem;
          color: #59d7d7;
        }
      }
    }
  }
}
       
        }
      }
    }
  }
  .place-carousel-container {
    padding: 0;
   
    .swiper {
      display: flex;
      padding-top: 1em;
      // width: auto;
      .swiper-wrapper {
        display: flex;
        height: 10em;
        .swiper-slide {
          // margin: 0 !important;
          padding: 0 0.2em;
          .place-container {
            width: auto;
            height: 10em;
            margin: 0;
            border-radius: 0.5em;
            display: grid;
            align-items: end;
            color: white;
            .title {
              font-family: $font-gotham-medium;
              font-size: 0.87rem;
              label {
                // display: block;
                // white-space: nowrap;
                // width: 7.5em;
                // overflow: hidden;
                // text-overflow: ellipsis;
              }
            }
            .distance {
              label {
                font-size: 1.3rem;
                font-family: $font-gotham-medium;
              }
              span {
                font-size: 0.8rem;
                font-family: $font-gotham-book;
              }
            }
          }
        }
      }
    }
    .btn-row {
      margin-top: 0.9rem;
      padding: 0.5em  0.7em;
      &.desktop{
        margin: 0;
        padding: 0;
      }
      .btn-tranparent {
        border-radius: 0.5em;
        height: 3em;
        padding: 0.5em;
        font-family: $font-gotham-medium;
        font-size: 0.85rem;
        background-color: transparent;
        color: #707070;
        border: 0.01em solid #707070;
        box-shadow: none;
        &.desktop{
          border: none;
          width: auto;
          color: #77A2C1;
        }
      }
    }
  }
  .activity-card-container {
    grid-area: left;
    // @media only screen and (min-width: 768px) {
    //   .card{
    //     height: 15em;
    //   }
    // }
    .card {
      // height: 11em;
      margin-top: 1em;
      padding: 0.4rem;
      background: rgba(255, 255, 255, 0.5);
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      // backdrop-filter: blur(15px);
      .card-img-top {
        border-radius: 0.5rem !important;
        object-fit: cover;
    height: 10em;
      }
      .card-body {
        padding: 0;
        padding-top: 0.5rem;
        .card-text {
          font-family: $font-gotham-bold;
          // font-weight: bold;
          font-size: 0.75rem;
          line-height: 0.875rem;
          color: #303345;
          .rating-row {
            .rating-label {
              display: flex;
              padding-top: 0.2rem;
              padding-bottom: 0.4rem;
              font-family: $font-gotham-book;
              font-size: x-small;
              color: #707070;
            }
            .p-rating {
              gap: 0.1rem;
              margin-left: 1em;
              .p-rating .p-rating-item .p-rating-icon {
                color: #495057;
                transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
                font-size: 1rem;
              }
            }
          }
          .title-label {
            margin-bottom: 0.4em;
            display: block;
            white-space: nowrap;
            max-width: 13em;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .price-row {
            .price-label-before-discount {
              font-family: Verdana, Geneva, Tahoma, sans-serif;
              font-size: x-small;
              -webkit-text-decoration-line: line-through; /* Safari */
              text-decoration-line: line-through;
            }
            .price-label-after-discount {
              display: flex;
              align-items: end;
              justify-content: start;
            }
          }
        }
      }
    }
      .btn-row {
        margin-top: 0.9rem;
        padding: 0.5em  0.7em;
        &.desktop{
          margin: 0;
          padding: 0;
        }
        .btn-tranparent {
          border-radius: 0.5em;
          height: 3em;
          padding: 0.5em;
          font-family: $font-gotham-medium;
          font-size: 0.85rem;
          background-color: transparent;
          color: #707070;
          border: 0.01em solid #707070;
          box-shadow: none;
          &.desktop{
            border: none;
            width: auto;
          color: #77A2C1;

          }
        }
    }
  }
  .event-card-container {
    grid-area: right;
    .card-col {
      display: grid;
      padding: 0.5em 0em;
      margin-top: 1em;
    }
    .card {
      border-radius: 0.5rem;
      margin: 0em 0;
      .card-body {
        padding: 2em 2em 1em 2em;
        color: #f5f5f5;
        .card-title {
          // font-family: $font-gotham-medium;
          font-size: 1rem;
          margin: 0;
          max-height: 1em;
          overflow: hidden;
        }
        .card-text {
          margin-top: 0.4em;
          font-size: 0.85rem;
          max-height: 3em;
          overflow: hidden;
          // font-family: $font-gotham-book;
        }
        .btn-activity {
          padding: 0.5em 1em;
          margin-top: 2em;
          background: #f5f5f5;
          // font-family: $font-gotham-medium;
          box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
          color: #707070;
          font-size: 0.85rem;
          text-transform: capitalize;
        }
      }
    }
    .btn-row {
      margin-top: 0.9rem;
      padding: 0.5em  0.7em;
      &.desktop{
        margin: 0;
        padding: 0;
      }
      .btn-tranparent {
        border-radius: 0.5em;
        height: 3em;
        padding: 0.5em;
        font-family: $font-gotham-medium;
        font-size: 0.85rem;
        background-color: transparent;
        color: #707070;
        border: 0.01em solid #707070;
        box-shadow: none;
        &.desktop{
          border: none;
          width: auto;
          color: #77A2C1;

        }
      }
    }
  }
  .grid-container {
    display: unset;
  }
  @media screen and (width: 2000px) {
    .grid-container {
      display: grid;
      display: grid;
      grid-template-areas: "left left left right right right";
      gap: 10px;
      padding: 10px;
    }
    .event-card-container {
      width: 100%;
    }
    .activity-card-container {
      width: 100%;
    }
  }
}

.place-container {
  overflow-x: hidden;
  // height: 100vh;
  .place-listing-container {
    position: relative;
    // bottom: 1em;
    border-radius: 1em 1em 0 0;
    // background-color: white;
    .place-listing-row {
      position: relative;
      top: -3rem;
    }
    .card-container {
      margin: 0.5em;
      box-shadow: 0 0 5px rgb(179, 179, 179);
      padding: 0.8rem;
      img {
        border-radius: 0.5em !important;
      object-fit: cover;
       height: 11em;
      }
      .card-body {
        padding: 0em !important;
        .title {
          color: #303345;
          font-size: 0.875rem;
          font-weight: bold;
          margin-top: 1.5em;
          // font-family: $font-gotham-bold;
        }
        .rating-row{
          align-items: center;
          .p-rating{
            padding: 0;
            gap: 0.2rem;
          }
        }
        .title-label {
          color: #a9a9a9;
          font-size: 0.75rem;
        }
        .badge-row {  
          margin: 0;
          padding: 0;
          .badge {
            margin-top: 1em;
            margin: 0.2em;
            label {
              font-size: 0.75rem;
              padding: 5px 10px;
              // margin-right: 0.5rem;
              border-radius: 0.5em;
              background: rgba(67, 67, 67, 0.1);
              color: #707070;
            }
          }
        }
      }
    }
  }
}

.ticket-container {
  overflow-x: hidden;
  .header-image {
    height: 10em;
    object-fit: cover;
    align-items: center;
    text-align: center;
    position: relative;
    z-index: 0;
    width: 100%;
    height: 20em;
    margin: -7em 0em -12em 0em;
  }
  .ticket-title-container {
    .title {
      label {
        font-weight: bold;
        // font-family: $font-gotham-medium;
        font-size: 1.25rem;
        line-height: 1.2em;
      }
    }
    .badge-row {
      .badge {
        margin-top: 1em;
        text-align: center;
        background: rgba(67, 67, 67, 0.1);
        margin-left: 0.5em;
        margin-right: 0.5em;
        width: auto;
        label {
          padding: 5px 10px;
          color: #707070;
          font-size: 0.75rem;
        }
      }
      .badge:first-child {
        margin-left: 0 !important;
      }
    }
  }
  .mobile-view {
    display: unset;
  }
  .desktop-view {
    display: none;
  }
  .container-title {
    margin-top: 1.5rem;
    margin-bottom: 0.8rem;
    label {
      display: flex;
      align-items: center;
      font-family: $font-gotham-bold;
      color: #303345;
      font-size: 1.25rem;
      div {
        margin-right: 0.3rem;
        background: #59d7d7;
        height: 1.2em;
        width: 0.3em;
        border-radius: 0.5em;
      }
    }
  }
  .container-subtitle {
    margin-top: 1rem;
    margin-bottom: 0.3rem;
    label {
      display: flex;
      align-items: center;
      // font-family: $font-gotham-bold;
      color: #303345;
      font-size: 1rem;
    }
  }
  .header-container {
    .title {
      color: #303345;
      font-size: 1.25rem;
      font-family: $font-gotham-bold;
      line-height: 1.2em;
      padding: 1rem 0;
      padding-top: 0;
    }
    .badge-row {
      .badge {
        label {
          font-size: 0.75rem;
          padding: 0.5rem 0.8rem;
          border-radius: 0.5em;
          background: rgba(67, 67, 67, 0.1);
          color: #59d7d7;
        }
      }
    }
  }
  .operation-container {
    .content-container {
      // font-family: $font-gotham-light;
      // font-family: "gothamBook";
      // font-weight: lighter;
      font-size: 	0.875rem;
      font-weight: 500;
      color: #707070;
      background: #e9f5ff;
      border-radius: 0.5rem;
      padding: 0.2rem 0.5rem;
      svg {
        margin: 0 0.5em;
      }
    }
  }
  .detail-container {
    .content-container {
      // font-family: $font-gotham-light;
      font-size: 0.8125rem;
      // font-weight: bold;
      color: #888daa;
      background: #e9f5ff;
      border-radius: 0.5rem;
      padding: 0.5rem 1rem;
      .detail-row {
        // .detail-text {
        .detail-title {
          font-weight: bold;
          // font-family: $font-gotham-medium;
          font-size: 0.875rem;
          padding: 0.2rem 0rem;
        }
        .row {
          display: flex;
          width: 100%;
          align-items: start;
          padding: 0.2rem 0rem;
          font-size: 0.875rem;
          span {
            &.hide-content{
              white-space: normal !important;
            }
            padding: 0;
            white-space: nowrap;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            svg {
              margin-right: 0.5rem;
            }
          }
          svg {
            width: auto;
          }
        }
        // }
      }
    }
  }
  .description-container {
    .container-subtitle {
      label {
        font-size: 1rem;
        font-weight: $font-gotham-medium;
      }
    }
    .content-container {
      margin-bottom: 1em;
      &.hide-height {
        max-height: 10em;
        overflow: hidden;
      }
      .listing {
        padding: 0;
        height: auto;

        color: #888daa;
        // line-height: 1.3rem;
        ul {
          margin-bottom: 0;
        }
        li {
          padding: 0.5rem 0;
          font-size: 0.85rem, $font-gotham-book;
        }
      }
    }
  }
  .history-container {
    padding: 0;
    .content-container {
      .history{
        &.hide-height {
          height: 5em;
          overflow: hidden;
        }
      }
      .paragraph {
        // font-family: $font-gotham-book;
        font-size: 0.875rem;
        height: auto;
        &.hide-height {
          height: 2em;
          overflow: hidden;
        }
        padding-left: 0;
        color: #888daa;
        line-height: 1.3rem;
      }
    }
  }
  .expect-container {
    .content-container {
      .description {
        align-items: center;
        label {
          color: #888daa;
          font-size: 0.75rem;
          padding: 0;
          align-items: start;
          display: flex;
          svg {
            margin-right: 0.5em;
          }
        }
      }
    }
  }
  .promotion-container {
    .content-container {
      padding: 0;
      .swiper {
        display: flex;
        // padding-top: 1em;
        .swiper-wrapper {
          display: flex;
          .promotion-container {
            background: #f2f8fd;
            padding: 0.3rem;
            border-radius: 0.8rem;
            .banner-image {
              border-radius: 0.6rem;
            }
          }
        }
      }
    }
  }
  .package-container {
    .content-container {
      padding: 0;
      .detail-button {
        font-size: 12px;
        color: #0267ff;
        font-family: $font-gotham-book;
      }
      .package-card {
        margin-top: 0.5em;
        border: 0.15rem solid #d6d6d6;
        border-radius: 0.5rem;
        padding: 0.5em 1rem;
        &.type-3 {
          margin-top: 1em;
          padding: 1rem 1.5rem;
          background: #ffffff;
        }
        .title {
          font-family: $font-gotham-bold;
          font-size: 0.75rem;
        }
        ul {
          padding: 0;
          margin-top: 0.5em;
          margin-bottom: 0;
        }
        .detail {
          list-style-type: none;
          line-height: 1.1em;
          width: 100%;
          color: #707070;
          font-size: 0.75rem;
          position: relative;
          top: -0.5rem;
        }
        .price {
          color: #41b1cd;
          font-family: $font-gotham-bold;
          font-size: 0.875rem;
        }
        .package-card-title {
          color: #707070;
          font-size: 0.875rem;
          margin-top: 1.3em;
          // font-weight: bold;
          // font-family: $font-gotham-book;
          input {
            background: none;
            border: none;
            color: #707070;
            font-size: 0.875rem;
            font-weight: bold;
            font-family: "gothamBook";
          }
        }
        .option-card {
          div {
            width: auto;
            padding: 0;
          }
          input[type="radio"]:checked + label {
            background: #f2f8fd;
            color: #41b1cd;
            border: 1.5px solid #41b1cd;
            border-radius: 6px;
            box-shadow: none;
            font-weight: bold;
            text-transform: capitalize;
            margin: 0.5rem;
          }
          input[type="radio"]:focus + label {
            box-shadow: none;
          }
          label {
            // font-family: $font-gotham-medium;
            background: #ffffff;
            color: #888daa;
            border: 1.5px solid #d6d6d6;
            border-radius: 6px;
            box-shadow: none;
            padding: 0.6em 1em;
            font-weight: bold;
            text-transform: capitalize;
            margin: 0.5rem;
            width: auto;
          }
        }
      }
      .package-standard {
        margin-top: 0.5em;
        .title {
          font-family: $font-gotham-bold;
          font-size: 0.75rem;
        }
        .detail {
          color: #707070;
          font-size: 0.75rem;
          position: relative;
          top: -0.5rem;
        }
        .price {
          color: #41b1cd;
          font-family: $font-gotham-bold;
          font-size: 0.875rem;
        }
        .package-card-title {
          color: #707070;
          font-size: 0.875rem;
          margin-top: 1.3em;
          // font-weight: bold;
          // font-family: $font-gotham-book;
          input {
            background: none;
            border: none;
            color: #707070;
            font-size: 0.875rem;
            font-weight: bold;
            font-family: "gothamBook";
          }
        }
        .option-card {
          div {
            width: auto;
            padding: 0;
          }
          input[type="radio"]:checked + label {
            background: #f2f8fd;
            color: #41b1cd;
            border: 1.5px solid #41b1cd;
            border-radius: 6px;
            box-shadow: none;
            font-weight: bold;
            text-transform: capitalize;
            margin: 0.5rem;
          }
          input[type="radio"]:focus + label {
            box-shadow: none;
          }
          label {
            margin-left: 0 !important;
            background: #ffffff;
            color: #888daa;
            border: 1.5px solid #d6d6d6;
            border-radius: 6px;
            box-shadow: none;
            padding: 0.6em 1em;
            font-weight: bold;
            text-transform: capitalize;
            margin: 0.5rem;
            width: auto;
          }
        }
      }
    }
  }
  @media only screen and (min-width: 768px) {
    .mobile-view {
      display: none;
    }
    .desktop-view {
      display: unset;
    }
  }
}

.package-container {
  .content-container {
    padding: 0;
    .detail-button {
      font-size: 12px;
      color: #0267ff;
      font-family: $font-gotham-book;
    }
    .package-card {
      margin-top: 0.5em;
      border: 0.15rem solid #d6d6d6;
      border-radius: 0.5rem;
      padding: 0.5em 1rem;
      &.type-3 {
        margin-top: 1em;
        padding: 1rem 1.5rem;
        background: #ffffff;
      }
      .title {
        font-family: $font-gotham-bold;
        font-size: 0.75rem;
      }
      ul {
        padding: 0;
        margin-top: 0.5em;
        margin-bottom: 0;
      }
      .detail {
        list-style-type: none;
        line-height: 1.1em;
        width: 100%;
        color: #707070;
        font-size: 0.75rem;
        position: relative;
        top: -0.5rem;
      }
      .price {
        color: #41b1cd;
        font-family: $font-gotham-bold;
        font-size: 0.875rem;
      }
      .package-card-title {
        color: #707070;
        font-size: 0.875rem;
        margin-top: 1.3em;
        // font-weight: bold;
        // font-family: $font-gotham-book;
        input {
          background: none;
          border: none;
          color: #707070;
          font-size: 0.875rem;
          font-weight: bold;
          font-family: "gothamBook";
        }
      }
      .option-card {
        div {
          width: auto;
          padding: 0;
        }
        input[type="radio"]:checked + label {
          background: #f2f8fd;
          color: #41b1cd;
          border: 1.5px solid #41b1cd;
          border-radius: 6px;
          box-shadow: none;
          font-weight: bold;
          text-transform: capitalize;
          margin: 0.5rem;
        }
        input[type="radio"]:focus + label {
          box-shadow: none;
        }
        label {
          background: #ffffff;
          color: #888daa;
          border: 1.5px solid #d6d6d6;
          border-radius: 6px;
          box-shadow: none;
          padding: 0.6em 1em;
          font-weight: bold;
          text-transform: capitalize;
          margin: 0.5rem;
          width: auto;
        }
      }
    }
    .package-standard {
      margin-top: 0.5em;
      .title {
        font-family: $font-gotham-bold;
        font-size: 0.75rem;
      }
      .detail {
        color: #707070;
        font-size: 0.75rem;
        position: relative;
        top: -0.5rem;
      }
      .price {
        color: #41b1cd;
        font-family: $font-gotham-bold;
        font-size: 0.875rem;
      }
      .package-card-title {
        color: #707070;
        font-size: 0.875rem;
        margin-top: 1.3em;
        // font-weight: bold;
        // font-family: $font-gotham-book;
        input {
          background: none;
          border: none;
          color: #707070;
          font-size: 0.875rem;
          font-weight: bold;
          font-family: "gothamBook";
        }
      }
      .option-card {
        div {
          width: auto;
          padding: 0;
        }
        input[type="radio"]:checked + label {
          background: #f2f8fd;
          color: #41b1cd;
          border: 1.5px solid #41b1cd;
          border-radius: 6px;
          box-shadow: none;
          font-weight: bold;
          text-transform: capitalize;
          margin: 0.5rem;
        }
        input[type="radio"]:focus + label {
          box-shadow: none;
        }
        label {
          margin-left: 0 !important;
          background: #ffffff;
          color: #888daa;
          border: 1.5px solid #d6d6d6;
          border-radius: 6px;
          box-shadow: none;
          padding: 0.6em 1em;
          font-weight: bold;
          text-transform: capitalize;
          margin: 0.5rem;
          width: auto;
        }
      }
    }
  }
}

.ticket-container-desktop {
  margin: 0em 15em;
  overflow-x: hidden;
  .header-image {
    height: 10em;
    align-items: center;
    text-align: center;
    object-fit: cover;

    position: relative;
    z-index: 0;
    width: 100%;
    height: 20em;
    margin: -7em 0em -10em 0em;
  }
  .ticket-title-container {
    .title {
      label {
        font-family: $font-gotham-medium;
        font-size: 1.25rem;
        line-height: 1.2em;
      }
    }
    .badge-row {
      .badge {
        margin-top: 1em;
        text-align: center;
        background: rgba(67, 67, 67, 0.1);
        margin-left: 0.5em;
        margin-right: 0.5em;
        width: auto;
        label {
          padding: 5px 10px;
          color: #707070;
          font-size: 0.75rem;
          // font-family: $font-gotham-book;
        }
      }
      .badge:first-child {
        margin-left: 0 !important;
      }
    }
  }
  .mobile-view {
    display: unset;
  }
  .desktop-view {
    display: none;
  }
  .container-title {
    margin-top: 1.5rem;
    margin-bottom: 0.8rem;
    label {
      display: flex;
      align-items: center;
      font-family: $font-gotham-bold;
      color: #303345;
      font-size: 1.25rem;
      div {
        margin-right: 0.3rem;
        background: #59d7d7;
        height: 1.2em;
        width: 0.3em;
        border-radius: 0.5em;
      }
    }
  }
  .container-subtitle {
    margin-top: 1rem;
    margin-bottom: 0.3rem;
    label {
      display: flex;
      align-items: center;
      font-family: $font-gotham-bold;
      color: #303345;
      font-size: 1rem;
    }
  }
  .header-container {
    .title {
      color: #303345;
      font-size: 1.25rem;
      font-family: $font-gotham-bold;
      line-height: 1.2em;
      padding: 1rem 0;
      padding-top: 0;
    }
    .badge-row {
      .badge {
        label {
          font-size: 0.75rem;
          padding: 0.5rem 0.8rem;
          border-radius: 0.5em;
          background: rgba(67, 67, 67, 0.1);
          color: #59d7d7;
        }
      }
    }
  }
  .operation-container {
    .content-container {
      // font-family: $font-gotham-light;
      font-size: 0.875rem;
      // font-weight: lighter;
      // font-weight: bold;
      color: #707070;
      background: #e9f5ff;
      border-radius: 0.5rem;
      padding: 0.2rem 0.5rem;
      svg {
        margin: 0 0.5em;
      }
    }
  }
  .detail-container {
    .content-container {
      // font-family: $font-gotham-light;
      font-size: 0.8125rem;
      // font-weight: bold;
      color: #888daa;
      background: #e9f5ff;
      border-radius: 0.5rem;
      padding: 0.5rem 1rem;
      .detail-row {
        // .detail-text {
        .detail-title {
          font-weight: bold;
          // font-family: $font-gotham-medium;
          font-size: 0.875rem;
          padding: 0.2rem 0rem;
        }
        .row {
          display: flex;
          width: 100%;
          align-items: start;
          padding: 0.2rem 0rem;
          font-size: 0.875rem;
          span {
            &.hide-content{
              white-space: normal !important;
            }
            // font-family: $font-gotham-bold;
            padding: 0;
            white-space: nowrap;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            svg {
              margin-right: 0.5rem;
            }
          }
          svg {
            width: auto;
          }
        }
        // }
      }
    }
  }
  .description-container {
    .container-subtitle {
      label {
        font-size: 1rem;
        font-weight: $font-gotham-medium;
      }
    }
    .content-container {
      margin-bottom: 1em;
      &.hide-height {
        max-height: 10em;
        overflow: hidden;
      }
    }
  }
}

.service-container {
  overflow-x: hidden;
  // padding-top: 6em;
  padding-bottom: 3em;
  height: 100vh;
  .common-header-main{
    position: relative !important;
  }
  .header {
    color: #f5f5f5;
    font-family: $font-merriweather-bold;
    font-size: 1.75rem;
    padding: 1em;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    line-height: 1.3em;
  }
  .fixed-container {
    // bottom: 0em;
    width: 100%;
    min-height: 70%;
    border-radius: 1.5em 1.5em 0 0;
    background: #fafbfb;
    padding: 1em 0.5em;
    position: absolute;
    .service-category-container {
      padding-bottom: 5em;
    }
      .listing {
        padding: 0;
        height: auto;

        color: #888daa;
        line-height: 1.3rem;
        ul {
          margin-bottom: 0;
        }
        li {
          padding: 0.5rem 0;
          font-size: 0.85rem, $font-gotham-book;
        }
      }
    }
  
  .history-container {
    padding: 0;
    .content-container {
      .history{
        &.hide-height {
          height: 5em;
          overflow: hidden;
        }
      }
      .paragraph {
        // font-family: $font-gotham-book;
        font-size: 0.875rem;
        height: auto;
        &.hide-height {
          height: 2em;
          overflow: hidden;
        }
        padding-left: 0;
        color: #888daa;
        line-height: 1.3rem;
      }
    }
  }
  .expect-container {
    .content-container {
      .description {
        align-items: center;
        label {
          
          color: #888daa;
          font-size: 0.75rem;
          padding: 0;
          align-items: center;
          display: flex;
          svg {
            margin-right: 0.5em;
          }
        }
      }
    }
  }
  .promotion-container {
    .content-container {
      padding: 0;
      .swiper {
        display: flex;
        // padding-top: 1em;
        .swiper-wrapper {
          display: flex;
          .promotion-container {
            background: #f2f8fd;
            padding: 0.3rem;
            border-radius: 0.8rem;
            .banner-image {
              border-radius: 0.6rem;
            }
          }
        }
      }
    }
  }
  .package-container {
    .content-container {
      padding: 0;
      .detail-button {
        font-size: 12px;
        color: #0267ff;
        font-family: $font-gotham-book;
      }
      .package-card {
        margin-top: 0.5em;
        border: 0.15rem solid #d6d6d6;
        border-radius: 0.5rem;
        padding: 0.5em 1rem;
        &.type-3 {
          margin-top: 1em;
          padding: 1rem 1.5rem;
          background: #ffffff;
        }
        .title {
          font-family: $font-gotham-bold;
          font-size: 0.75rem;
        }
        ul {
          padding: 0;
          margin-top: 0.5em;
          margin-bottom: 0;
        }
        .detail {
          list-style-type: none;
          line-height: 1.1em;
          width: 100%;
          color: #707070;
          font-size: 0.75rem;
          position: relative;
          top: -0.5rem;
        }
        .price {
          color: #41b1cd;
          font-family: $font-gotham-bold;
          font-size: 0.875rem;
        }
        .package-card-title {
          color: #707070;
          font-size: 0.875rem;
          margin-top: 1.3em;
          // font-weight: bold;
          // font-family: $font-gotham-book;
          input {
            background: none;
            border: none;
            color: #707070;
            font-size: 0.875rem;
            font-weight: bold;
            font-family: "gothamBook";
          }
        }
        .option-card {
          div {
            width: auto;
            padding: 0;
          }
          input[type="radio"]:checked + label {
            background: #f2f8fd;
            color: #41b1cd;
            border: 1.5px solid #41b1cd;
            border-radius: 6px;
            box-shadow: none;
            font-weight: bold;
            text-transform: capitalize;
            margin: 0.5rem;
          }
          input[type="radio"]:focus + label {
            box-shadow: none;
          }
          label {
            background: #ffffff;
            color: #888daa;
            border: 1.5px solid #d6d6d6;
            border-radius: 6px;
            box-shadow: none;
            padding: 0.6em 1em;
            font-weight: bold;
            text-transform: capitalize;
            margin: 0.5rem;
            width: auto;
          }
        }
      }
      .package-standard {
        margin-top: 0.5em;
        .title {
          font-family: $font-gotham-bold;
          font-size: 0.75rem;
        }
        .detail {
          color: #707070;
          font-size: 0.75rem;
          position: relative;
          top: -0.5rem;
        }
        .price {
          color: #41b1cd;
          font-family: $font-gotham-bold;
          font-size: 0.875rem;
        }
        .package-card-title {
          color: #707070;
          font-size: 0.875rem;
          margin-top: 1.3em;
          // font-weight: bold;
          // font-family: $font-gotham-book;
          input {
            background: none;
            border: none;
            color: #707070;
            font-size: 0.875rem;
            font-weight: bold;
            font-family: "gothamBook";
          }
        }
        .option-card {
          div {
            width: auto;
            padding: 0;
          }
          input[type="radio"]:checked + label {
            background: #f2f8fd;
            color: #41b1cd;
            border: 1.5px solid #41b1cd;
            border-radius: 6px;
            box-shadow: none;
            font-weight: bold;
            text-transform: capitalize;
            margin: 0.5rem;
          }
          input[type="radio"]:focus + label {
            box-shadow: none;
          }
          label {
            margin-left: 0 !important;
            background: #ffffff;
            color: #888daa;
            border: 1.5px solid #d6d6d6;
            border-radius: 6px;
            box-shadow: none;
            padding: 0.6em 1em;
            font-weight: bold;
            text-transform: capitalize;
            margin: 0.5rem;
            width: auto;
          }
        }
      }
    }
  }
  @media only screen and (min-width: 768px) {
    .mobile-view {
      display: none;
    }
    .desktop-view {
      display: unset;
    }
  }
}

.ticket-container-desktop::-webkit-scrollbar {
  display: none !important;
}

.activity-container {
  padding: 0 12px;
  overflow-x: hidden;
  // height: 100vh;
  background-color: white;
  .container-desktop{
    .container-listing-activity{
      // padding: 0 24px;
    }
    .scroll-container{
      // padding: 0 12px;
    }
    .header-activity {
      background: radial-gradient(61.15% 61.43% at 50% 38.57%, #dd66a6 0%, rgba(152, 112, 10, 0.63) 57.81%, #0c8aaa 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      font-family: $font-merriweather-bold;
      font-size: 2rem;
      // font-weight: bold;
    }
     .activity-title-text2{
      font-family: "gothamMedium";
      font-size: 0.75rem;
      color: #888daa;
    }
    .activity-listing-container {
      position: relative;
      padding-bottom: 5em;
      border-radius: 1em 1em 0 0;
      background-color: white;
      .activity-listing-row {
        .ripple {
          border-radius: 1em;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        }
        .activity-card {
          margin: 0.5rem 0;
        }
        .card-container {
          border-radius: 1em;
          .card-img-top {
            border-radius: 1em 1em 0 0;
            object-fit: cover;
            height: 11em;
          }
          .card-body {
            padding: 1rem 1.5rem;
            .title {
              font-size: 0.875rem;
              font-weight: bold;
              margin-top: 0.5em;
            }
            .rating {
              // font-family: $font-gotham-light;
              color: #707070;
              font-size: 0.75em;
              .p-rating {
                gap: 0.5em;
              }
            }
            .detail {
              color: #707070;
              width: auto;
              // background: rgba(67, 67, 67, 0.1);
              border-radius: 0.3rem;
              margin-top: 0.5rem;
              margin-right: 0.7em;
              padding: 5px 10px;
              // text-align: center;
              align-items: center;
              display: flex;
              label{
                border-radius: 0.4em;
                font-size: 0.75rem;
                line-height: 1em;
              }
            }
            .btn-activity-detail {
              display: block;
              margin-top: auto;
              margin-bottom: auto;
              text-align: center;
              button {
                text-transform: capitalize;
                font-size: 13.6px;
                font-weight: bold;
                color: #888DAA;
                background: #f5f5f5;
                border-radius: 1em;
                box-shadow: none;
                // padding: 0.5rem 2rem;
              }
            }
          }
        }
      }
    }
  }
}

.description-container {
  .container-subtitle {
    label {
      font-size: 1rem;
      font-weight: $font-gotham-medium;
    }
  }
  .content-container {
    margin-bottom: 1em;
    &.hide-height {
      max-height: 10em;
      overflow: hidden;
    }
    .listing {
      padding: 0;
      height: auto;
      font-size: 0.875rem;
      color: #888daa;
      // line-height: 1.3rem;
      ul {
        margin-bottom: 0;
      }
      li {
        padding: 0.5rem 0;
        font-size: 0.85rem, $font-gotham-book;
      }
    }
  }
}

.history-container {
  padding: 0;
  .content-container {
    .history{
      &.hide-height {
        height: 5em;
        overflow: hidden;
      }
    }
    .paragraph {
      // font-family: $font-gotham-book;
      font-size: 0.875rem;
      height: auto;
      &.hide-height {
        height: 2em;
        overflow: hidden;
      }
      padding-left: 0;
      color: #888daa;
      line-height: 1.3rem;
    }
  }
}

.event-container {
  overflow-x: hidden;
  .event-title {
    display: flex;
    align-items: center;
    // font-family: $font-gotham-bold;
    color: #303345;
    font-size: 1.25rem;
    font-weight: bold;
    padding: 1rem 0.5rem;
  }
  .event-story-container {
    .story-container {
      img {
        min-height: 3.5em;
        border-radius: 50%;
        width: 3.5em;
        margin-left: auto;
        margin-right: auto;
        display: block;
        font-family: $font-gotham-book;
      }
      label {
        color: #797979;
        font-size: small;
        text-align: center;
      }
    }
  }
  .place-see-more {
    color: #4d8ae7;
    padding: 1rem 0.5rem;
    font-size: 0.875rem;
  }
  .place-container {
    .ripple {
      // box-shadow: 0px 2px 4px rgba(131, 131, 131, 0.25);
      border-radius: 0.5rem;
      height: 6.5em;
      box-shadow: none;
    }
    .card {
      box-shadow: 0px 2px 4px rgba(131, 131, 131, 0.25);
      font-family: $font-gotham-book;
      height: 6.5em;
      .image {
        padding-right: 0;
        img {
          height: 100%;
          border-radius: 0.5rem 0 0 0.5rem;
        }
      }
      .title {
        min-height: 1em;
        font-size: 1.125rem;
        color: #707070;
      }
      .detail {
        font-size: 0.75rem;
        color: #a9a9a9;
      }
      .price {
        font-size: 0.75rem;
        color: #a9a9a9;
        padding: 1em 0em;
        padding-bottom: 0;
        span {
          font-family: $font-gotham-bold;
          color: #59d7d7;
        }
      }
    }
  }
  .activity-card-container {
    // display: flex;
    .card {
      padding: 0.4rem;
      background: rgba(255, 255, 255, 0.5);
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      // backdrop-filter: blur(15px);
      .card-img-top {
        border-radius: 0.5rem !important;
      object-fit: cover;
       height: 7em;
      }
      .card-body {
        padding: 0;
        padding-top: 0.5rem;
        .card-text {
          font-family: $font-gotham-bold;
          // font-weight: bold;
          font-size: 0.75rem;
          line-height: 0.875rem;
          color: #303345;
          .rating-row {
            align-items: center;
            .rating-label {
              // padding-top: 0.2rem;
              // padding-bottom: 0.4rem;

              font-family: $font-gotham-book;
              font-size: 1em;
              color: #707070;
            }
            .p-rating {
              gap: 0.1rem;
              .p-rating .p-rating-item .p-rating-icon {
                color: #495057;
                transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
                font-size: 0.8rem;
              }
            }
          }
          .price-row {
            .price-label-before-discount {
              font-family: Verdana, Geneva, Tahoma, sans-serif;
              font-size: x-small;
              -webkit-text-decoration-line: line-through; /* Safari */
              text-decoration-line: line-through;
            }
            .price-label-after-discount {
              display: flex;
              align-items: end;
              justify-content: start;
            }
          }
        }
      }
    }
    .btn-row {
      margin-top: 1rem;
      .btn-tranparent {
        padding: 0.5em;
        background-color: transparent;
        color: #707070;
        border: 1px solid #707070;
      }
    }
  }
  .coming-event-container {
    .swiper {
      overflow: visible !important;
      margin-bottom: 3em;
      .swiper-slide {
        min-width: 15em;
      }
      .container {
        color: #f5f5f5;
        .title {
          // font-family: $font-gotham-bold;
          background: rgba(0, 0, 0, 0.6);
          width: auto;
          white-space: nowrap;
          border-radius: 0.5rem;
          font-size: 1.125rem;
          margin-bottom: 0.5rem;
          span {
            white-space: nowrap;
            max-width: 13em;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .detail {
          font-weight: normal;
          font-size: 0.875rem;
          line-height: 1.5em;
          height: 3em;
          overflow: hidden;
        }
      }
    }
    .swiper-pagination {
      bottom: -2em;
    }
    .coming-event-join-now {
      display: flex;
      justify-content: end;
      button {
        background: #f5f5f5;
        color: #707070;
        text-transform: capitalize;
        border-radius: 2rem;
        width: 7em;
        font-size: 0.875rem;
        font-weight: bold;
        padding: 0.3rem;
        margin-top: 1rem;
      }
    }
    .btn-organize-event {
      // margin: 0 2rem;
      button {
        box-shadow: none;
        border: 0.1rem solid #707070;
        border-radius: 0.5rem;
        text-transform: lowercase;
        font-size: medium;
        // font-family: $font-gotham-bold;
        font-weight: bold;
        padding: 0.3rem;
        color: #707070;
        background-color: transparent;
      }
    }
  }
}

.service-container {
  overflow-x: hidden;
  // padding-top: 6em;
  padding-bottom: 3em;
  height: 100vh;
  .header {
    color: #f5f5f5;
    font-family: $font-merriweather-bold;
    font-size: 1.75rem;
    padding: 1em;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    line-height: 1.3em;
  }
  .fixed-container {
    // bottom: 0em;
    width: 100%;
    min-height: 100%;
    border-radius: 1.5em 1.5em 0 0;
    background: #fafbfb;
    padding: 1em 0.5em;
    position: absolute;
    .service-category-container {
      padding-bottom: 5em;
    }

    .service-formik-form {
      h3 {
        padding-top: 2rem;
        font-size: 1.25rem;
        font-family: $font-gotham-bold;
      }
      .button-container {
        margin: 3rem 0em 5em 0em;
        padding: 0 2rem;
        .btn-primary {
          background: $btn-color-blueLight;
          color: #f5f5f5;
          height: 3em;
          font-family: $font-gotham-bold;
          text-transform: capitalize;
          border-radius: 0.8rem;
          font-size: 1rem;
          box-shadow: 0px 2px 4px rgba(131, 131, 131, 0.25);
        }
        .btn-primary:disabled {
          background: $btn-color-disable;
          color: #888daa;
          font-family: $font-gotham-bold;
          text-transform: capitalize;
          border-radius: 0.8rem;
          font-size: 1.125rem;
          box-shadow: 0px 2px 4px rgba(131, 131, 131, 0.25);
        }
      }
      label {
        font-family: $font-gotham-book;
        font-size: 0.9rem;
        font-weight: 700;
      }
    }
    .service-category-card-container {
      .service-category-card-titile {
        padding: 0.8rem 0;
        label {
          padding: 0.3rem 0.8rem;
          width: auto;
          background: #ffffff;
          border: 1px solid #d6d6d6;
          color: #303345;
          font-family: $font-gotham-book;
          font-weight: bolder;
          border-radius: 0.5rem;
          &.active {
            border: 1.5px solid #41b1cd;
            display: flex;
            align-items: center;
            div {
              background-color: #41b1cd;
              width: 0.3em;
              height: 0.3em;
              border-radius: 50%;
              margin-left: 0.5em;
            }
          }
        }
      }
      .service-card {
        &.active {
          background: #f5f5f5;
        }
        margin: 0.5rem 0;
        text-align: center;
        background: #ffffff;
        border: 1px solid #d6d6d6;
        width: 100%;
        padding: 2em 0 1em 0;
        border-radius: 0.5rem;
        font-size: 0.875rem;
      }
    }
  }
  .service-room {
    &.fixed-bottom{    
      z-index: 0!important;
    }
    background-color: #ffffff;
    padding: 1rem 0;
    border-top: 0.1em solid #000000;
    box-shadow: none;
    .helper {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 2em;
      button {
        background-color: $btn-color-blueDark;
        box-shadow: 0px 2px 4px rgba(131, 131, 131, 0.25) !important;
        width: 100%;
        border-radius: 0.5rem;
        color: #f5f5f5;
        text-transform: capitalize;
        font-size: 1rem;
        font-family: $font-gotham-bold;
      }
    }
  }
  .service-history-container {
    background: #fafbfb;
    .navbar {
      width: 100%;
    }
    .service-modal-container {
      overflow-x: hidden;
      // padding-top: 6em;
      padding-bottom: 6em;
      .service-history {
        display: flex;
        align-items: center;
        padding: 0.5em;
        border-bottom: 0.1em solid #707070;
        label {
          font-size: 0.9rem;
        }
        span {
          // font-family: $font-gotham-light;
          font-size: 0.9rem;
          color: #303345;
        }
        p {
          font-size: 1rem;
          color: $font-color;
          font-family: $font-gotham-medium;
          text-align: end;
          margin: 0;
        }
        .room-service-label {
          // font-family: $font-gotham-bold;
          font-weight: bold;
          display: block;
          white-space: nowrap;
          max-width: 15em;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .status {
          padding: 0.2em;
          text-align: center;
          font-weight: bold;
          // font-family: $font-gotham-bold;
          justify-content: end;
          .span {
            font-size: 0.75rem !important;
            border-radius: 0.8em;
            padding: 0.2em 1em;
            background: $btn-color-blueDark;
            color: white;
            width: auto;
          }
          .span-c{
            font-size: 0.75rem !important;
            padding: 0.2em 1em;
            border-radius: 0.8em;
            background: rgb(152, 220, 144);
            color: white;
            width: auto;
          }
        }
        .line {
          border: 0.5px solid #bbb6b6;
        }
      }
    }
  }
}

.check-out-container {
  .title{
    font-family: "gothamMedium";
  }
  .badge-row {
    margin: 0;
  }
  .modal-dialog {
    animation: fadeInDown 500ms ease-in-out;
  }
  .content-container {
    padding: 1em 0;
    .description-container {
      .content-container {
        .listing {
          &.hide-height {
            height: 8em;
            overflow: hidden;
          }
          padding-left: 1rem;
          color: #888daa;
          line-height: 1.3rem;
          li {
            padding: 0.5rem 0;
          }
        }
      }
    }
    .detail-container {
      margin-top: 1em;
      margin-bottom: 1em;
      // font-family: $font-gotham-light;
      font-size: 0.8125rem;
      font-weight: bold;
      color: #888daa;
      background: #f2f8fd;
      border-radius: 0.5rem;
      padding: 0.5rem 1rem;
      .detail-row {
        .detail-title {
          font-size: 0.875rem;
          font-weight: bold;
          padding: 0.2rem 0.5rem;
        }
        .detail-text {
          // height: 5em;
          font-weight: normal;
          overflow: hidden;
          &.hideHeight {
            height: 3em;
          }
          span {
            display: block;
            word-wrap: break-word;
          }
          svg {
            margin-right: 0.5rem;
          }
        }
        .button-see-more {
          color: #0267ff;
        }
      }
    }
    .selected-package-container {
      .selected-package-title {
        margin-top: 1em;
        color: #707070;
        font-size: 0.875rem;
        // font-family: $font-gotham-book;
        font-weight: bold;
      }
      .choose-service-card {
        // &.errors-msg {
        //   border: 0.1em solid #ea3943;
        // }
        border-radius: 0.5rem;
        color: #707070;
        // font-family: $font-gotham-book;
        border: 0.1em solid #d6d6d6;
        padding: 0.5rem 1.5rem;
        margin: 0.5rem 0;
        font-size: 0.75rem;
        .count-field {
          display: flex;
          align-items: center;
          padding: 0.5rem 0;
          .service-label {
            color: #707070;
            // font-size: 13px;
            line-height: 1.1em;
          }
          .price {
            color: #707070;
            // font-weight: bold;
            font-size: 0.875rem;
          }
          .button {
            display: flex;
            justify-content: center;
            align-items: center;
            label {
              color: #707070;
              font-weight: bold;
              font-size: 1.125rem;
              width: 1.8em;
              text-align: center;
            }
          }
          .description {
            color: #707070;
            font-size: x-small;
          }
          .label{
            font-size: 0.813rem;
          }
        }
      }
      .error-message {
        font-size: small;
        text-align: end;
      }
    }
  }
  .planning-payment-container {
    .palnning-payment {
      p {
        font-family: $font-gotham-book;
        font-size: 0.95rem;
        color: #303345;
      }
      label {
        font-family: $font-gotham-medium;
        font-size: 0.95rem;
        color: #303345;
      }
      span {
        font-family: $font-gotham-book;
        font-size: 0.95rem;
        color: #303345;
      }
    }
  }
  .checkout-payment {
    background-color: #ffffff;
    padding: 1rem 0;
    box-shadow: none;
    position: relative;
    .helper {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 2em;
      button {
        background-color: $btn-color-blueDark;
        box-shadow: 0px 2px 4px rgba(131, 131, 131, 0.25) !important;
        width: 100%;
        border-radius: 0.5rem;
        color: #f5f5f5;
        text-transform: capitalize;
        font-size: 1rem;
        font-family: $font-gotham-bold;
      }
    }
    .progress {
      position: absolute;
      top: 0;
      .progress-bar {
        background: #59d7d7;
      }
    }
    .pay {
      color: #707070;
      font-size: 1rem;
      font-family: $font-gotham-medium;
      align-items: center;
      display: grid;
      text-align: center;
      span {
        font-size: 0.85rem;
      }
    }
    .button-pay {
      align-items: center;
      .btn-primary {
        background-color: #59d7d7;
        font-size: 0.9rem;
        font-family: $font-gotham-medium;
        color: #f5f5f5;
      }
    }
  }
}

.payment-container {
  background-color: #f5f5f5;
  min-height: 100vh;
  padding-bottom: 5em;
  padding-left: 0 !important;
  padding-right: 0 !important;
  .payment-method-container {
    background: #ffffff;
    margin-bottom: 1em;
    align-items: center;
    padding: 0.5rem;
    .payment-method-radio {
      //  height: 2em;
      align-items: center;
      padding: 0.5em;
      margin: 1em 1em;
      border: 0.2em solid #6abed2c0;
      border-radius: 0.5em;
      .form-check-input:checked{
        border-color: #41b1cd;
      }
      .form-check-input[type=radio]:checked:after{
        background-color: #41b1cd;
      }
      .payment-method-border{
        .form-check-input:checked{
          border-color: #41b1cd;
        }
        .form-check-input[type=radio]:checked:after{
          background-color: #41b1cd;
        }
      }
      .form-check-input {
        color: #41b1cd;
      }
      .form-check-input::after {
        color: #41b1cd;
        border-color: #41b1cd;
        // background: #41B1CD;;
      }
      label {
        font-size: 0.875rem;
        font-family: $font-gotham-medium;
        color: #41b1cd;
      }
      &.disabled {
        border: 0.2em solid rgba(220, 217, 217, 0.519);
        label {
          font-size: 0.875rem;
          font-family: $font-gotham-medium;
          color: #70707063;
        }
      }
    }
  }
  .detail-container {
    background-color: #ffffff;
    margin-bottom: 1rem;
    padding: 0.5rem;
    min-height: 10em;
    padding-bottom: 2em;
    position: relative;
    .booking-container {
      margin: 0.5em 0em;
      background: #ffffff;
      .payment-detail-title {
        font-size: 1rem;
        color: #707070;
        font-weight: bold;
        // font-family: $font-gotham-medium;
      }
      .payment-detail-item {
        font-size: 0.813rem;
        color: #707070;
        // font-family: $font-gotham-book;
      }
    }
    .content-container {
      // font-family: $font-gotham-light;
      font-size: 0.8125rem;
      font-weight: bold;
      color: #888daa;
      // background: #F2F8FD;
      border-radius: 0.5rem;
      padding: 0.5rem;
      .package-option-label {
        font-size: 1rem;
        // font-family: $font-gotham-bold;
        color: #707070;
      }
      .package-name-label{
        font-weight: bold;
        padding: 0.5em 0;
      }
      .service {
        margin-bottom: 1.5em;
        .package-name-label {
          // font-family: $font-gotham-book;
          margin: 0.5em 0;
          label {
            padding: 0;
          }
        }
        .detail-listing {
          line-height: 1em;
          font-size: 0.813rem;
        }
      }
      .service:last-of-type {
        margin-bottom: 0;
      }
      .detail-listing {
        font-size: 0.813rem;
        font-weight: normal;
        .detail-listing-addon {
          .i {
            padding-left: 1em;
            padding-right: 0.5em;
            width: auto !important;
          }
          label {
            padding: 0;
            padding-left: 5.75em;
          }
          label:nth-child(2) {
            width: auto !important;
            padding-left: 0;
          }
        }
      }
    }
    .package-price {
      margin: 0 0.5em !important;
      color: #303345;
      // font-family: $font-gotham-bold;
      font-weight: bold;
      font-size: 1rem;
      position: absolute;
      bottom: 0.5em;
      label {
        padding: 0;
      }
    }
  }
  .contact-container {
    background-color: #ffffff;
    margin-bottom: 1rem;
    padding: 1rem 0.5rem;
    .content-container {
      padding: 0;
      .package-option-label {
        color: #888daa;
        font-size: 12px;
        font-weight: normal;
        // font-family: $font-gotham-book;
        padding-left: 1rem;
      }
      .box {
        border: 1px solid #ececec;
        border-radius: 0.5rem;
        padding: 1rem;
        margin-top: 0.5rem;
      }
      .contact-button {
        margin: 0;
        display: flex;
        align-items: center;
        .contact-label {
          &.errors {
            animation: blinker 1s linear infinite;
          }
          padding: 0.5rem 0;
          padding-bottom: 0;
          color: #ea3943;
          font-size: 12px;
          display: flex;
          // font-family: $font-gotham-book;
          svg {
            margin-right: 0.3rem;
          }
        }
        button {
          border-radius: 0.5rem;
          background: #f5f5f5;
          color: #707070;
          box-shadow: none;
          padding: 0.5rem;
          width: 5rem;
          font-size: 13px;
          // font-family: $font-gotham-bold;
          text-transform: capitalize;
          svg {
            margin-right: 0.3rem;
          }
        }
      }
      .edit-button {
        margin: 0;
        margin-bottom: 0.5rem;
        display: flex;
        align-items: center;
        span {
          color: #41b1cd;
          background: #f5f5f5;
          border: 0.2em solid #41b1cd;
          font-size: 12px;
          padding: 0.3rem;
          width: auto;
          border-radius: 0.5rem;
          font-family: $font-gotham-bold;
        }
        button {
          margin-left: 1em;
          border-radius: 0.5rem;
          background: #f5f5f5;
          color: #707070;
          box-shadow: none;
          padding: 0.5rem;
          width: 3rem;
          font-size: 13px;
          font-family: $font-gotham-bold;
          text-transform: capitalize;
          svg {
            margin-right: 0.3rem;
          }
        }
      }
      .user-contact-detail {
        font-size: 0.875rem;
        // font-family: $font-gotham-book;
        padding: 0.2rem;
        label {
          color: #888daa;
        }
        span {
          color: #303345;
        }
      }
    }
  }
  .notice-container {
    background-color: white;
    padding: 1em 0;
    font-size: 0.875rem;

    label {
      color: #888daa;
      span {
        color: #ea3943;
        font-family: $font-gotham-bold;
        &.t-and-c{
          color: #41b1cd;
        }
        &.t-and-c-list{
          color: black;
          font-size: 0.87em;
        font-family: $font-gotham-medium;
        }
      }
    }
  }
  .bottom-payment {
    .btn-buy-now {
      padding: 0 2em;
    }
  }
}
.contact-formik-form {
  h3 {
    padding-top: 2rem;
    font-size: 1.25rem;
    font-family: $font-gotham-bold;
  }
  .button-container {
    margin: 3rem 0;
    padding: 0 2rem;
    button {
      background: $btn-color-blueLight;
      color: #f5f5f5;
      font-family: $font-gotham-medium;
      text-transform: capitalize;
      border-radius: 0.8rem;
      font-size: 1rem;
      box-shadow: 0px 2px 4px rgba(131, 131, 131, 0.25);
    }
  }
  .form-group{
    &.error-border{
      border: 1px solid #0000002b;
    }
  }
}

.contact-header{
  font-family: $font-gotham-medium;
  font-size: 1rem;
}

.login-container {
  height: 40em;
  // display: flex;
  // align-items: center;
  .login-header {
    align-items: center;

    .login-header-title {
      text-align: center;
      color: #303345;
      font-weight: 350;
      font-size: 1.45rem;
      font-family: $font-gotham-medium;
    }
    .login-header-button {
      align-items: center;
      justify-content: center;
      display: flex;
      color: $font-color;
      font-size: 0.9rem;
      font-family: $font-gotham-medium;
    }
  }
  .login-text {
    margin: 2em 0em 2.8em 0em;
    .login-text-text {
      margin-top: 1em;
      font-family: $font-gotham-medium;
      font-style: normal;
      font-weight: 600;
      font-size: 1.23rem;
      display: flex;
      color: #303345;
    }
    .login-text-text2 {
      font-family: $font-gotham-book;
      font-style: normal;
      font-size: 0.9rem;
      line-height: 17px;
      align-items: center;
      color: #707070;
    }
  }
  .login-label {
    margin: 1em;
    font-family: $font-gotham-bold;
    font-size: 0.9rem;
    color: $btn-color-blueDark;
    text-decoration: 0.25em $btn-color-blueDark underline;
  }
  .login-field {
    margin: 1em;
    input::placeholder {
      font-family: $font-gotham-book;
      font-size: 1.1rem;
    }
  }
  .error-message-line {
    .error-message-row {
      align-items: center;
      text-align: center;
      margin-top: -0.5em;
      .error-message {
        color: red;
        font-size: 0.75rem;
        text-align: start;
      }
      .code-time {
        font-size: 0.75rem;
      }
    }
  }
  .message-btt {
    margin: 1em 0em;
    .form-label {
      // font-family: $font-gotham-light;
      font-style: normal;
      font-weight: 500;
      font-size: 0.9rem;
    }
  }

  .form-check {
    margin: 1.2em;
    .form-check-input[type="checkbox"]:checked {
      background-color: $btn-color-blueLight;
    }
    .form-check-input:checked {
      border-color: $btn-color-blueLight;
    }
    .form-check-label {
      font-family: $font-gotham-medium;
      font-size: 0.73rem;
      .check-text {
        color: $btn-color-blueDark;
      }
    }
  }
  .button-container {
    align-items: center;
    justify-content: center;
    margin-top: 1.5em;
    .submit-button {
      width: 19em;
      height: 3em;
      border-radius: 0.5em;
      background: $btn-color-blueLight; 
      color: $btn-color-disable;
      font-size: 1.15rem;
      font-family: $font-gotham-medium;
      box-shadow: none;
    }
    .submit-button:disabled {
      border-radius: 1em;
      background: $btn-color-disable;
      color: #888daa;
      font-family: $font-gotham-medium;
    }
  }
  .code-center {
    align-items: center;
    .verfication-button {
      position: absolute;
      left: 75%;
      width: 6em;
      .code-button {
        width: 6em;
        padding: 0.35em;
        font-size: 0.7rem;
        align-items: center;
        display: flex;
        justify-content: center;
        background: $btn-color-disable;
        color: $font-color;
        box-shadow: none;
      }
    }
  }
}

.promotion-page-container {
  overflow-x: hidden;
  background: white;
  height: 100vh;
  .promotion-title-text {
    background: radial-gradient(57.14% 57.14% at 48.98% 42.86%, #dd9f00 0%, #dd66a6 52.6%, #41b1cd 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: $font-merriweather-bold;
    font-size: 2rem;
  }
  .promotion-title-text2 {
    font-family: $font-gotham-medium;
    font-size: 0.75rem;
    color: #888daa;
  }

  .promotion-card {
    display: inline-block;
    background: #ffffff;
    margin: 0.7em 0em 0.7em 0em;
    .card-body {
      .img{
        height: 8.5em;
        object-fit: cover;
        border-radius: 1em;
      }
      .card-title {
        font-size: 0.875rem;
        font-weight: bold;
        // font-family: $font-gotham-medium;
        margin-top: 1.5em;
      }
      .col-6 {
        font-size: 0.75rem;
        // font-family: $font-gotham-book;
        color: #888daa;
      }
      .promotion-ticket {
        // font-family: $font-gotham-book;
        font-size: 0.65rem;
        margin: 1em -0.2em;
        align-items: center;
        .promotion-label{
          // font-family: $font-gotham-book;
          font-size: 0.75rem;
          align-items: center;
        }
        .promotion-ticket-last {
          border-radius: 0.5em;
          border: 1px dashed #d6d6d6;

          color: $font-color;
          font-family: $font-gotham-book;
        }
        .promotion-ticket-money {
          font-family: $font-gotham-book;
          text-align: end;
          font-size: 0.75rem;
          .original-ticket {
            text-decoration-line: line-through;
          }
          .ticket-money {
            font-size: 1.02rem;
            color: $btn-color-blueLight;
            font-family: $font-gotham-medium;
          }
        }
      }
      .promotion-btt {
        width: 19.2em;
        height: 3em;
        margin: auto;
        margin-top: 1.5em;
        justify-content: center;
        border-radius: 0.6em;
        background: $btn-color-blueLight;
        font-family: $font-gotham-medium;
        font-size: 1rem;
      }
    }
  }
}

.promotion-listing-container {
  overflow-x: hidden;
  height: 100vh;
  .header-image {
    position: absolute;
    height: 15em;
    top: 0em;
    left: 0;
    box-shadow: inset 80px 0px 90px rgba(27, 26, 26, 0.59);
  }
  .header-container {
    .title {
      color: #303345;
      font-size: 1.25rem;
      font-family: $font-gotham-bold;
      line-height: 1.2em;
      padding: 1rem 0;
      padding-top: 0;
    }
    .badge-row {
      .badge {
        label {
          font-size: 0.75rem;
          padding: 0.5rem 0.8rem;
          border-radius: 0.5em;
          background: rgba(67, 67, 67, 0.1);
          color: #59d7d7;
        }
      }
    }
  }
  .promotion-detail-container-title {
    margin-top: 1.5rem;
    margin-bottom: 0.8rem;
    label {
      display: flex;
      align-items: center;
      font-family: $font-gotham-bold;
      color: #303345;
      font-size: 1.25rem;
      div {
        margin-right: 0.3rem;
        background: #59d7d7;
        height: 1.2em;
        width: 0.3em;
        border-radius: 0.5em;
      }
    }
  }
  .detail-container {
    .content-container {
      // font-family: $font-gotham-light;
      font-size: 0.8125rem;
      font-weight: bold;
      color: #888daa;
      background: #f2f8fd;
      border-radius: 0.5rem;
      padding: 0.5rem 1rem;
      .detail-row {
        .detail-title {
          font-size: 0.875rem;
          font-weight: bold;
          padding: 0.2rem 1rem;
        }
        .detail-text {
          white-space: nowrap;
          width: 50px;
          overflow: hidden;
          text-overflow: ellipsis;
          svg {
            margin-right: 0.5rem;
          }
        }
      }
    }
  }
  .description-container {
    .content-container {
      .listing {
        &.hide-height {
          height: 8em;
          overflow: hidden;
        }
        padding-left: 1rem;
        color: #888daa;
        line-height: 1.3rem;
        li {
          padding: 0.5rem 0;
        }
      }
    }
  }
  .package-container {
    .package-detail-container-title {
      margin-top: 1.5rem;
      margin-bottom: 0.8rem;
      label {
        display: flex;
        align-items: center;
        font-family: $font-gotham-bold;
        color: #303345;
        font-size: 1.25rem;
        div {
          margin-right: 0.3rem;
          background: #59d7d7;
          height: 1.2em;
          width: 0.3em;
          border-radius: 0.5em;
        }
      }
    }
    .content-container {
      .title {
        color: #303345;
        font-family: $font-gotham-medium;
        line-height: 1.2em;
        padding: 1rem 0;
        padding-top: 0;
      }
      .detalis {
        color: blue;
        font-size: 0.8rem;
        // font-family: $font-gotham-light;
      }
      .package-type {
        color: #303345;
        font-family: $font-gotham-book;
        line-height: 1.2em;
        font-size: 0.8rem;
        padding: 1em;
      }
    }
    .badge-row {
      .badge {
        label {
          font-size: 0.75rem;
          padding: 0.5rem 0.8rem;
          border-radius: 0.5em;
          background: rgba(67, 67, 67, 0.1);
          color: #59d7d7;
        }
      }
    }
    .btn-group {
      box-shadow: none;
      .btn-primary {
        background: #ffffff;
        border: 1.5px solid #888daa;
        border-radius: 6px;
        color: #888daa;
        margin: 1em 0em;
      }
      .btn-check:checked + .btn-primary {
        background: #fff4e4;
        border: 1.5px solid $btn-color-blueDark;
        border-radius: 6px;
        color: $btn-color-blueDark;
        margin: 1em 0em;
      }
    }
    .buy-button {
      justify-content: center;
      margin: 0.6em 0em;
      .ripple {
        width: 90%;
        background-color: $btn-color-blueLight;
        border-radius: 0.7em;
      }
    }
  }
}
.operation-container {
  .content-container {
    // font-family: $font-gotham-light;
    font-size: 	0.875rem;
    // font-weight: lighter;
    // font-weight: bold;
    color: #707070;
    background: #e9f5ff;
    border-radius: 0.5rem;
    padding: 0.2rem 0.5rem;
    svg {
      margin: 0 0.5em;
    }
  }
}
.expect-container {
  .content-container {
    .content-container-row {
      margin-bottom: 1em;
    }
    img {
      min-height: 10em;
      max-height: 23em;
      border-radius: 0.5em;
    }
    .description {
      align-items: center;
      label {
        font-weight: initial;
        color: #888daa;
        font-size: 0.75rem;
        padding: 0;
        align-items: start;
        display: flex;
        margin-top: 1em;
        svg {
          margin-right: 0.5em;
        }
      }
    }
  }
}
.promotion-container {
  .content-container {
    padding: 0;
    .swiper {
      display: flex;
      // padding-top: 1em;
      .swiper-wrapper {
        display: flex;
        .promotion-container {
          background: #f2f8fd;
          padding: 0.3rem;
          border-radius: 0.8rem;
          .banner-image {
            border-radius: 0.6rem;
          }
        }
      }
    }
  }
}
.room-container {
  // height: 100vh;
  padding-bottom: 5em;
  overflow-x: hidden;
  background: white;
  justify-content: center;
  .room-checkbox {
    margin: 0em 0em 1em 0em;
    .btn{
      box-shadow: none;
    }
    .btn-primary {
      font-size: 0.625rem;
      color: $font-color;
      display: flex;
      flex-direction: row;
      font-family: $font-gotham-medium;
      justify-content: center;
      background: #f5f5f5;
      // box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 1em;
      padding: 1em;
    }
    .btn-check:checked + .btn-primary {
      padding: 0.8em;
      color: #41b1cd;
      font-size: 0.625rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      font-family: $font-gotham-medium;
      background: #f5f5f5;
      border: 0.2em solid #41b1cd;
      border-radius: 1em;
    }
  }
  .room-card {
    border-radius: 1em;
    align-items: center;
    justify-content: center;
    min-height: 15em;
    .card-img {
      position: initial;
      object-fit: cover;
      height: 15em;
      border-radius: 0.5em;
    }
    .background-room {
      padding: 0.5em 0.8em;
      border-radius: 0em 0em 0.5em 0.5em;
      background: rgba(0, 0, 0, 0.2);
      // background: #f5f5f5;
      backdrop-filter: blur(1em);
      position: absolute;
      bottom: 0;
      right: 0;
      .card-title {
        color: #f5f5f5;
        font-weight: bold;
        line-height: 1.2em;
        text-align: start;
        font-size: 1.125rem;
        margin: 0;
        // font-family: $font-gotham-bold;
        // display: block;
        // white-space: nowrap;
        // max-width: 13em;
        // overflow: hidden;
        // text-overflow: ellipsis;
      }
      .room-money {
        color: #f5f5f5;
        font-family: $font-gotham-book;
        text-align: end;
        font-size: 0.75rem;
        span {
          color: #f5f5f5;
          font-family: $font-gotham-medium;
          text-align: end;
          font-size: 1.02rem;
        }
      }
      .room-size {
        padding-top: 0.2em;
        padding-bottom: 0.2em;
        color: #f5f5f5;
        // font-family: $font-gotham-medium;
        text-align: start;
        font-size: 0.875rem;
      }
      .room-icon {
        color: #f5f5f5;
        text-align: end;
      }
    }
  }
}
.room-listing-container {
  overflow-x: hidden;
  height: 100vh;
  .swiper {
    height: 13em;
    margin: -5em 0em -10.9em 0em;
    z-index: 0;
    .header-image {
      position: absolute;
      height: 100%;
      top: 0em;
      left: 0;
      box-shadow: inset 80px 0px 90px rgba(27, 26, 26, 0.59);
    }
  }
  .swiper-desktop {
    height: 30em;
    margin: -5em 0em -10.9em 0em;
    z-index: 0;
    .header-image {
      position: absolute;
      height: 100%;
      top: 0em;
      left: 0;
      box-shadow: inset 80px 0px 90px rgba(27, 26, 26, 0.59);
    }
  }
  .header-container {
    .title {
      color: #303345;
      font-size: 1.25rem;
      font-family: $font-gotham-medium;
      line-height: 1.2em;
      padding: 1rem 0;
      padding-top: 0;
    }
    // .badge-row {
    //   .badge {
    //     margin-top: 1em !important;
    //     margin-right: 0.5em !important;
    //     width: auto;
    //     label {
    //       border-radius: 0.5em;
    //       padding: 5px 10px;
    //       color: #707070;
    //       font-size: 0.75rem;
    //       // font-family: "gothamBook";
    //     }
    //   }
    // }
  }
  .property-container {
    .property-detail-container-title {
      margin-top: 1.5rem;
      margin-bottom: 0.8rem;
      label {
        display: flex;
        align-items: center;
        font-family: $font-gotham-bold;
        color: #303345;
        font-size: 1.25rem;
        div {
          margin-right: 0.3rem;
          background: #59d7d7;
          height: 1.2em;
          width: 0.3em;
          border-radius: 0.5em;
        }
      }
    }
    .time-container {
      background: #e9f5ff;
      label {
        font-family: $font-gotham-book;
        font-size: 0.85rem;
        color: #707070;
      }
    }
    .popular-facilitices {
      background: #e9f5ff;
      border-radius: 0.5rem;
      padding: 0.5rem;
      label {
        font-family: $font-gotham-medium;
        color: $font-color;
        font-size: 1rem;
      }
      .icon {
        align-items: center;
        text-align: center;
        .icon-label {
          font-size: 0.65rem;
          font-family: $font-gotham-medium;
          color: $font-color;
        }
      }
    }

    .service {
      margin: 1em 0em;

      label {
        margin: 0.3em 0em;
        font-size: 1rem;
        font-family: $font-gotham-medium;
      }
    }
  }

  .expect-container {
    .room-detail-container-title {
      margin-top: 1.5rem;
      margin-bottom: 0.8rem;
      label {
        display: flex;
        align-items: center;
        font-family: $font-gotham-bold;
        color: #303345;
        font-size: 1.25rem;
        div {
          margin-right: 0.3rem;
          background: #59d7d7;
          height: 1.2em;
          width: 0.3em;
          border-radius: 0.5em;
        }
      }
    }
    .room-detail-container-text {
      // font-family: $font-gotham-light;
    }
    .content-container {
      .description {
        align-items: center;
        label {
        font-weight: lighter;
          color: #888daa;
          font-size: 0.75rem;
          padding: 0;
          align-items: center;
          display: flex;
          svg {
            margin-right: 0.5em;
          }
        }
      }
    }
  }
  .promotion-container {
    .place-detail-container-title {
      margin-top: 1.5rem;
      margin-bottom: 0.8rem;
      label {
        display: flex;
        align-items: center;
        font-family: $font-gotham-bold;
        color: #303345;
        font-size: 1.25rem;
        div {
          margin-right: 0.3rem;
          background: #59d7d7;
          height: 1.2em;
          width: 0.3em;
          border-radius: 0.5em;
        }
      }
    }
    .content-container {
      padding: 0;
      .swiper {
        display: flex;
        // padding-top: 1em;
        .swiper-wrapper {
          display: flex;
          .promotion-container {
            background: #f2f8fd;
            padding: 0.3rem;
            border-radius: 0.8rem;
            .banner-image {
              border-radius: 0.6rem;
            }
          }
        }
      }
    }
  }
  .booking-container {
    align-items: center;
    text-align: center;
    justify-content: center;
    .btn-primary {
      width: 18em;
      height: 3em;
      border-radius: 0.5em;
      background: $btn-color-blueLight;
      color: $btn-color-disable;
      font-size: 1.15rem;
      font-family: $font-gotham-medium;
      // box-shadow: none;
      backdrop-filter: blur(15px);
      border-radius: 10px;
      position: fixed;
      top: 86%;
      z-index: 3;
    }
  }
  .scroll-component-desktop {
    position: relative;
    min-height: 100vh;
    border-radius: 1em 1em 0 0;
    background-color: #fafbfbfb;
    padding: 2em 0.3em 5em 0.3em;
    // margin-top: 8rem;
    margin: 0em 25em 0em 25em;
    animation: fadeInUp 500ms ease-in-out;
  }
}

.room-listing-container-desktop {
  overflow-x: hidden;
  // height: 100vh;
  .scroll-component{
    margin-top: 8rem;
  }
  .swiper {
    min-height: 25em;
    margin: -5em 0em -10.9em 0em;
    z-index: 0;
    .header-image {
      position: absolute;
      height: 25em;
      // height: auto;
      top: 0em;
      left: 0;
      box-shadow: inset 80px 0px 90px rgba(27, 26, 26, 0.59);
    }
  }
  .swiper-desktop {
    height: 30em;
    margin: -3em 0em -10.9em 0em;
    z-index: 0;
    .header-image {
      position: absolute;
      height: 100%;
      top: 0em;
      left: 0;
      box-shadow: inset 80px 0px 90px rgba(27, 26, 26, 0.59);
    }
  }
  .header-container {
    .title {
      font-weight: bold;
    font-size: 1.25rem;
    line-height: 1.2em;
      // color: #303345;
      // font-size: 1.25rem;
      // // font-family: $font-gotham-medium;
      // line-height: 1.2em;
      // padding: 1rem 0;
      // padding-top: 0;
    }
    .badge-row {
      .badge {
        margin-top: 1em;
        margin-right: 0.5em;
        label {
          border-radius: 0.5em;
          padding: 5px 10px;
          color: #707070;
          font-size: 0.75rem;;
          // font-family: "gothamBook";
        }
      }
    }
  }
  .property-container {
    .property-detail-container-title {
      margin-top: 1.5rem;
      margin-bottom: 0.8rem;
      label {
        display: flex;
        align-items: center;
        font-family: $font-gotham-bold;
        color: #303345;
        font-size: 1.25rem;
        div {
          margin-right: 0.3rem;
          background: #59d7d7;
          height: 1.2em;
          width: 0.3em;
          border-radius: 0.5em;
        }
      }
    }
    .time-container {
      background: #e9f5ff;
      label {
        font-family: $font-gotham-book;
        font-size: 0.85rem;
        color: #707070;
      }
    }
    .popular-facilitices {
      background: #e9f5ff;
      border-radius: 0.5rem;
      padding: 0.5rem;
      label {
        font-family: $font-gotham-medium;
        color: $font-color;
        font-size: 0.875rem;
      }
      .icon {
        align-items: center;
        text-align: center;
        .icon-label {
          font-size: 0.65rem;
          font-family: $font-gotham-medium;
          color: $font-color;
        }
      }
    }
    .service {
      margin: 1em 0em;
      label {
        margin: 0.3em 0em;
        font-size: 1rem;
        font-family: $font-gotham-medium;
      }
      span{
        font-size: 0.875rem;
      }
    }
  }

  .expect-container {
    .room-detail-container-title {
      margin-top: 1.5rem;
      margin-bottom: 0.8rem;
      label {
        display: flex;
        align-items: center;
        font-family: $font-gotham-bold;
        color: #303345;
        font-size: 1.25rem;
        div {
          margin-right: 0.3rem;
          background: #59d7d7;
          height: 1.2em;
          width: 0.3em;
          border-radius: 0.5em;
        }
      }
    }
    .room-detail-container-text {
      // font-family: $font-gotham-light;
    }
    .content-container {
      img{
        padding: 0;
        border-radius: 0.5rem;
      }
      .description {
        align-items: center;
        label {
          color: #888daa;
          font-size: 0.75rem;
          padding: 0;
          align-items: start;
          display: flex;
          svg {
            margin-right: 0.5em;
          }
        }
      }
    }
  }
  .promotion-container {
    .place-detail-container-title {
      margin-top: 1.5rem;
      margin-bottom: 0.8rem;
      label {
        display: flex;
        align-items: center;
        font-family: $font-gotham-bold;
        color: #303345;
        font-size: 1.25rem;
        div {
          margin-right: 0.3rem;
          background: #59d7d7;
          height: 1.2em;
          width: 0.3em;
          border-radius: 0.5em;
        }
      }
    }
    .content-container {
      padding: 0;
      .swiper {
        display: flex;
        // padding-top: 1em;
        .swiper-wrapper {
          display: flex;
          .promotion-container {
            background: #f2f8fd;
            padding: 0.3rem;
            border-radius: 0.8rem;
            .banner-image {
              border-radius: 0.6rem;
            }
          }
        }
      }
    }
  }
  .booking-container {
    align-items: center;
    text-align: center;
    justify-content: center;
    .btn-primary {
      margin-bottom: 1em;
      width: 18em;
      height: 3em;
      border-radius: 0.5em;
      background: $btn-color-blueLight;
      color: $btn-color-disable;
      font-size: 1rem;
      font-family: $font-gotham-medium;
      box-shadow: none;
      position: fixed;
      bottom: 1em;
      z-index: 3;
      box-shadow: 0px 12px 47px rgba(151, 151, 151, 0.627);
    }
  }
  .scroll-component-desktop {
    position: relative;
    min-height: 100vh;
    border-radius: 1em 1em 0 0;
    background-color: #fafbfbfb;
    padding: 2em 0.3em 5em 0.3em;
    // margin-top: 8rem;
    margin: 0em 25em 0em 25em;
    animation: fadeInUp 500ms ease-in-out;
  }
}
.room-listing-container-desktop::-webkit-scrollbar {
  display: none !important;
}

.planing-container {
  overflow-x: hidden;
  padding-top: 6em;
  // padding-bottom: 5em;
  // height: 100vh;
  .header {
    color: #f5f5f5;
    font-family: $font-merriweather-bold;
    font-size: 1.75rem;
    padding: 1em;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    line-height: 1.3em;
  }
  .fixed-container {
    min-height: 100vh;
    bottom: 0em;
    width: 100%;
    border-radius: 1.5em 1.5em 0 0;
    // background-color: white;
    background: #fafbfb;
    padding: 1em 0.5em;
    padding-bottom: 6em !important;
    .empty-data{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .h1{
      padding-top: 3em;
      display: flex;
      justify-content: center;
      color: #888daa;
      font-family: $font-gotham-medium;
      margin: auto;
    }
   
    .service-formik-form {
      h3 {
        padding-top: 2rem;
        font-size: 1.25rem;
        font-family: $font-gotham-bold;
      }
      .button-container {
        margin: 3rem 0;
        padding: 0 2rem;
        button {
          background: $btn-color-blueLight;
          color: #f5f5f5;
          font-family: $font-gotham-bold;
          text-transform: capitalize;
          border-radius: 0.8rem;
          font-size: 1.125rem;
          box-shadow: 0px 2px 4px rgba(131, 131, 131, 0.25);
        }
      }
      label {
        font-family: $font-gotham-book;
        font-size: 0.9rem;
        font-weight: 700;
      }
    }
    .service-category-card-container {
      .service-category-card-titile {
        padding: 0.8rem 0;
        label {
          padding: 0.3rem 0.8rem;
          width: auto;
          background: #ffffff;
          border: 1px solid #d6d6d6;
          color: #303345;
          font-family: $font-gotham-book;
          font-weight: bolder;
          border-radius: 0.5rem;
          &.active {
            border: 1.5px solid #41b1cd;
            display: flex;
            align-items: center;
            div {
              background-color: #41b1cd;
              width: 0.3em;
              height: 0.3em;
              border-radius: 50%;
              margin-left: 0.5em;
            }
          }
        }
      }
      .service-card {
        margin: 0.5rem 0;
        text-align: center;
        background: #ffffff;
        border: 1px solid #d6d6d6;
        width: 100%;
        padding: 2em 0 1em 0;
        border-radius: 0.5rem;
      }
    }
  }
  .planing-radio {
    overflow: scroll;
    text-align: center;
    // justify-content: center;
    align-items: center;
    border-radius: 1.25em 1.25em 0px 0px;
    height: 3em;
    border-bottom: 0.12em solid rgba(0, 0, 0, 0.25);
    // margin-bottom: 0.75em;
    margin: 0 0 1em 0;
    flex-wrap: nowrap;
    .radio {
      padding: 0 0.5em;
      label {
        width: 100%;
        text-transform: capitalize;
        font-size: 0.8125rem;
        .btn-secondary {
          font-family: $font-gotham-medium;
          width: 100%;
          color: #888daa;
          background: #f5f5f5;
          // box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
          padding: 0.5em 1em;
          border-radius: 0.4em;
          border: 0.2em solid #f5f5f5;
          // padding: 0.46em;
          padding: 0.5em;
          line-height: 0.8em;
          box-shadow: none;
        }
        .btn-check:checked + .btn-secondary {
          color: $btn-color-blueDark;
          width: 100%;
          flex-direction: row;
          align-items: flex-start;
          background: #f5f5f5;
          border: 0.2em solid #41b1cd;
          border-radius: 0.4em;
          box-shadow: none;
          padding: 0.5em;
          line-height: 0.8em;
        }
      }
    }
  }
  .planing-radio::-webkit-scrollbar {
    display: none !important;
  }
  .planing-card {
    // margin: 0, 75em;
    margin: 0.5em;
    .card-header {
      display: flex;
      align-items: center;
      padding: 0.7em 0.6em;
      .ticket-title {
        line-height: 1;
        font-size: 0.875rem;
        margin-left: 0.5em;
        white-space: nowrap;
        max-width: 17em;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .card-body {
      padding: 0.8em;
      .card-content {
        padding: 0 0.5em;
        button{
          font-size: 0.813rem;
        }
      }
    }
    label {
      font-family: $font-gotham-medium;
      font-size: 0.95rem;
    }
    img {
      height: 6em;
    object-fit: cover;

      // display: flex;
      // height: 100%;
      width: 100%;
      border-radius: 0.5em;
    }
    .planing-time {
      font-family: $font-gotham-medium;
      font-size: 0.75em;
      color: #77a2c1;
    }
    .planing-text {
      font-family: $font-gotham-medium;
      font-size: 0.75em;
      color: $font-color;
    }
    .btn-primary {
      font-family: $font-gotham-medium;
      border-radius: 0.5em;
      margin-top: 1em;
      text-transform: capitalize;
      color: #fff;
      background: $btn-color-blueLight;
      box-shadow: none;
    }
    .ex-btt {
      font-family: $font-gotham-medium;
      margin-top: 1em;
      color: #888daa;
      background: #f5f5f5;
    }
  }
}
.planing-listing-container {
  overflow-x: hidden;
  // height: 100vh;
  .header-image {
    position: absolute;
    height: 15em;
    top: 0em;
    left: 0;
    box-shadow: inset 80px 0px 90px rgba(27, 26, 26, 0.59);
  }
  .header-container {
    .title {
      color: #303345;
      font-size: 1.25rem;
      font-family: $font-gotham-medium;
      line-height: 1.15em;
      padding: 1rem 0;
      padding-top: 0;
    }
    .planing-details {
      .details-label {
        font-family: $font-gotham-bold;
        font-size: 1.2rem;
        margin: 1.5em 0em 0.5em 0em;
      }
      .line {
        margin-top: 0.5rem;
        border: 1px solid #dddddd;
      }
      .event-title {
        // font-family: $font-gotham-medium;
        font-weight: bold;
        font-size: 0.85rem;
        color: #303345;
      }
      .ticket-container {
        border: 0.1rem solid #d0d0d0;
        border-top: none !important;
        .event-title-row {
          background: #f5f5f5;
          .event-title {
            font-weight: normal;
            padding: 0.5em 1em;
            // font-family: $font-gotham-book;
            // font-weight: bold;
          }
        }
        .ticket-name {
          color: #707070;
          font-size: 0.75rem;
          padding: 0.8em;
        }
      }
      .purchase-information {
        padding: 0;
        margin: 1em 0em;
        .margin {
          color: #888daa;
          margin: 0;
          font-size: 0.875rem;
          height: 1.5em;
        }
        .used-on {
          color: $font-color;
          margin: 0em;
          font-size: 0.875rem;
          height: 1.5em;
            p{
              border-radius: 0.5em;
              padding: 0.5em 1em;
              color: $font-color;
              background: #f5f5f5;
              font-size: 0.875rem;
            }
        }
      }
    }
    .qr-container-planning {
      align-items: center;
      text-align: center;
        .p-calendar {
    padding-top: 1em;
    .p-datepicker {
      .p-datepicker-group-container {
        .p-datepicker-group {
          .p-datepicker-header {
            .button {
              width: 2rem;
              height: 1rem;
            }
            .p-datepicker-title {
              .p-link {
                font-size: 0.85em !important;
              }
            }
          }
          .p-datepicker-calendar-container {
            .p-datepicker-calendar {
              thead {
                tr {
                  th {
                    text-align: center;
                  }
                }
              }
              tbody{
                span{
                  font-family: $font-gotham-medium;
                }
              }
              font-size: small;
            }
          }
        }
      }
    }
  }
      .qr-code-svg {
        margin: 1em;
      }
      .aplly-row{
        margin: 0.5em 1em;
        justify-content: end;
        text-align: center;
        .aplly{
          height: auto;
          background: linear-gradient(180deg, #12b6df 0%, #59d7d7 100%);
          border-radius: 0.53em;
          width: auto;

          label{
            font-family: $font-gotham-medium;
            font-size: 0.6rem;
          }
        }
      }
     
      .booking-btn{
        align-items: center ;
        margin-top: 1em;
        background: #59D7D7;
        border-radius: 0.5em;
         font-size: 0.75rem;
      font-family: $font-gotham-bold;
      }
      .booking-no{
        color:  #000000;
        // font-family: $font-gotham-light;
      text-align: center;
      align-items: center;
      justify-content: center;
      }
      .planning-status {
        margin-top: 0.5em;
        align-items: center;
        text-align: center;
        justify-content: center;
        .used-paid {
          padding: 0.5em 1em;
          background: linear-gradient(180deg, #12b6df 0%, #59d7d7 100%);
          margin: 0.5em;
          font-family: $font-gotham-medium;
          font-size: 1rem;
          border-radius: 0.3em;
          color: #ffffff;
          width: auto;
        }
      }
    }
  }
}

.profile-container {
  overflow-x: hidden;
  padding-top: 6em;
  .header {
    color: #f5f5f5;
    font-family: $font-merriweather-bold;
    font-size: 1.75rem;
    padding: 1em;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    line-height: 1.3em;
  }
  .fixed-container {
    bottom: 3em;
    width: 100%;
    height: 100%;
    border-radius: 1.5em 1.5em 0 0;
    background: #fafbfb;
    padding-bottom: 6em;
    padding: 1em 0.5em;
    .emptyData{
      height: 70vh;
      justify-content: center;
      align-items: center;
      display: flex;
      img{
        height: 50%;
      }
    }
  }
  .profile-setting {
    margin: 1em 0em;
    label {
      font-family: $font-gotham-medium;
      font-size: 1.25rem;
    }
    .setting-body {
      margin: 1em 0em;
      border-bottom: 0.1em solid #d6d6d6;
      padding-bottom: 1em;
      label {
        font-size: 1rem;
        font-family: $font-gotham-book;
        color: $font-color;
      }
      .p-message{
        margin: 0;
        font-size: 0.75rem;
        // font-family: $font-gotham-light;
        color: $font-color;
      }
    }
  }
  .logout {
    text-decoration-line: underline;
    font-family: $font-gotham-bold;
    font-size: 1em;
  }
  .version {
    font-family: $font-gotham-bold;
    font-size: 0.8em;
    color: #888daa;
  }
  .data-referral {
    background: #f5f5f5;
    align-items: center;
    text-align: center;
    margin: 1em;
    box-shadow: inset 0px 0px 4px rgba(246, 186, 152, 0.25);
    .btn-css {
      padding: 0.3em;
      .btn-modal {
        width: 100%;
        padding-top: 0.7em;
        height: 3em;
        font-size: 0.85rem;
        text-align: center;
        background: $btn-color-blueDark;
        border-radius: 0.5em;
        color: $btn-color-disable;
        font-family: $font-gotham-bold;
      }
    }
    .btn-qrcopy {
      background: $btn-color-blueLight;
    }
  }
  .avatar {
    text-align: center;
    img {
      width: 7.125em;
      border-radius: 50%;
      height: 7.125em;
      margin: 1em 0em;
    }
  }
  .user-member {
    color: white;
    font-family: $font-gotham-medium;

    text-align: end;
    .agent {
      text-align: center;
      border-radius: 0.8em;
      font-size: 0.8rem;

      width: 3.56em;
      background: #405854;
    }
    .user {
      background: #6541cd;
      text-align: center;
      border-radius: 0.8em;
      font-size: 0.8rem;

      width: 3.56em;
    }
  }
}

.planing-listing-container {
  overflow-x: hidden;
  // height: 100vh;
  .scroll-component {
    padding-top: 1em;
    margin-top: 13em;
  }
  .header-image {
    position: absolute;
    height: 15em;
    top: 0em;
    left: 0;
    box-shadow: inset 80px 0px 90px rgba(27, 26, 26, 0.59);
  }
  .header-container {
    .title {
      color: #303345;
      font-size: 1.25rem;
      font-family: $font-gotham-medium;
      line-height: 1.15em;
      padding: 1rem 0;
      padding-top: 0;
    }
    .planing-details {
      .details-label {
        font-family: $font-gotham-bold;
        font-size: 1.2rem;
        margin: 1.5em 0em 0em 0em;
      }
      .line {
        margin: 0.5rem 0em;
        border: 1px solid #dddddd;
      }
      .event-title {
        // font-family: $font-gotham-medium;
        // font-weight: bold;
        font-size: 0.85rem;
        color: #303345;
      }
      .ticket-container {
        border: 0.1rem solid #d0d0d0;
        border-top: none !important;
        border-radius: 0.5em;
        .event-title-row {
          background: #d0d0d0;
          .event-title {
            // font-family: $font-gotham-book;
            // font-weight: bold;
          }
        }
        .ticket-name {
          color: #707070;
          // font-family: $font-gotham-medium;
          font-size: 0.875rem;
          padding: 0.8em;
        }
      }
      .purchase-information {
        padding: 0;
        margin: 1em 0em;
        .margin {
          // font-family: $font-gotham-medium;
          color: #888daa;
          margin: 0;
          font-size: 0.875rem;
          height: 1.5em;
        }
        .p-right{
          text-align: end;
        }
        .used-on {
          padding: 0.5em;
          p{
            color: $font-color;
            background: #f5f5f5;
            font-size: 0.875rem;
          }
        }
      }
    }
    .qr-container-planning {
      align-items: center;
      text-align: center;
      .qr-code-svg {
        margin: 1em;
      }
      .planning-status {
        align-items: center;
        text-align: center;
        justify-content: center;
        .used-paid {
          text-transform: capitalize;
          font-size: 0.9rem;
          background: linear-gradient(180deg, #12b6df 0%, #59d7d7 100%);
          margin: 0.5em;
          font-family: $font-gotham-medium;
          border-radius: 0.3em;
          color: #ffffff;
          width: auto;
        }
      }
    }
  }
}

.profile-container {
  overflow-x: hidden;
  padding-top: 6em;
  // height: 100vh;
  .header {
    color: #f5f5f5;
    font-family: $font-merriweather-bold;
    font-size: 1.75rem;
    padding: 1em;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    line-height: 1.3em;
  }
  .fixed-container {
    bottom: 3em;
    width: 100%;
    // height: 100%;
    min-height: 100%;
    border-radius: 1.5em 1.5em 0 0;
    background: #fafbfb;

    padding: 1em 0.5em;
    padding-bottom: 6em;
  }
  .profile-setting {
    margin-left: auto;
    margin-right: auto;
    margin-top: 1em;
    // margin: 1em 0em;
    label {
      font-family: $font-gotham-medium;
      font-size: 1.25rem;
    }
    .setting-body {
      border-bottom: 0.1em solid #d6d6d6;
      padding-bottom: 1em;
      label {
        font-size: 1rem;
        font-family: $font-gotham-book;
        color: $font-color;
      }
    }
  }
  .logout {
    text-decoration-line: underline;
    font-family: $font-gotham-bold;
    font-size: 1em;
  }
  .version {
    font-family: $font-gotham-bold;
    font-size: 0.8em;
    color: #888daa;
  }
  .data-referral {
    background: #f5f5f5;
    align-items: center;
    text-align: center;
    margin: 1em;
    box-shadow: inset 0px 0px 4px rgba(246, 186, 152, 0.25);
    .btn-css {
      padding: 0.3em;
      .btn-modal {
        width: 100%;
        padding-top: 0.7em;
        height: 3em;
        font-size: 0.85rem;
        text-align: center;
        background: $btn-color-blueDark;
        border-radius: 0.5em;
        color: $btn-color-disable;
        font-family: $font-gotham-bold;
      }
    }
    .btn-qrcopy {
      background: $btn-color-blueLight;
    }
  }
  .avatar {
    text-align: center;
    img {
      width: 7.125em;
      border-radius: 50%;
      height: 7.125em;
      margin: 1em 0em;
    }
  }
  .user-member {
    color: white;
    font-family: $font-gotham-medium;
    text-align: end;
    .agent {
      border-radius: 0.8em;
      font-size: 0.8rem;
      width: 3.56em;
      background: #405854;
    }
    .user {
      background: #6541cd;
      text-align: center;
      border-radius: 0.8em;
      font-size: 0.8rem;
      width: 3.56em;
    }
  }
  .user-name {
    font-family: $font-gotham-medium;
    font-size: 1.1rem;
    align-items: center;
    text-align: start;
    color: #707070;
  }
  .user-name2 {
    font-family: "gothamMedium";
    font-size: 1.1rem;
    text-align: center;
    color: #707070;
  }
  .data {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: $font-color;
    font-family: $font-gotham-bold;
    font-size: 1rem;
    text-transform: uppercase;
  }

  .content-container {
    font-family: $font-gotham-book;
    font-size: 0.725rem;
    color: #888daa;
    background: #fbf3e8;
    border-radius: 0.5rem;
    padding: 0.2rem 0.5rem;
    svg {
      margin: 0 0.5em;
    }
  }
}
.modal-content{
border-radius: 1em;
overflow: hidden;
  .profile-modal-container {
    border-radius: 0em  0em  2em 2em;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
    background: #fafbfb;
    overflow-y: 0;
    .image-css {
      justify-content: center;
      text-align: center;
      margin-top: 1em;
      .image {
        // border-radius: 50%;
        width: 7.125em;
        height: 7.125em;
      }
    }
    .camera-css {
      text-align: end;
      margin-bottom: -2em;
      .camera {
        margin: 0.7em -1em;
        font-size: 1.5rem;
      }
    }
    .edit-container {
      padding: 1em;
  
      .edit-modal {
        margin: 1.2em 0em;
        .form-control:disabled {
          background: none;
        }
        .edit-label {
          font-family: $font-gotham-book;
          font-size: 1rem;
        }
        .edit-p {
          // font-family: $font-gotham-light;
          font-size: 0.8rem;
        }
        span {
          font-family: $font-gotham-medium;
          font-size: 0.75rem;
          color: $font-color;
        }
      }
    }
  
    .info-background {
      background: #ffffff;
      .detalis-money {
        display: flex;
        align-items: center;
        font-family: $font-gotham-medium;
        color: #303345;
        font-size: 1.1rem;
      }
      label {
        display: flex;
        align-items: center;
        font-family: $font-gotham-medium;
        color: #303345;
        font-size: 1.25rem;
        margin: 0.4em 0em;
        div {
          margin-right: 0.3rem;
          background: #59d7d7;
          height: 1.2em;
          width: 0.3em;
          border-radius: 0.5em;
        }
        .package-name {
          font-family: $font-gotham-book;
          font-size: 1rem;
        }
       
      }
      span {
        // font-style: $font-gotham-light;
        font-size: 0.9rem;
        margin: auto;
      }
      p {
        // font-style: $font-gotham-light;
        font-size: 0.9rem;
        margin: auto;

      }
      .info-data {
        border: 0.07em solid #888daa;
        span {
          color: $font-color;
          // font-family: $font-gotham-light;
        }
        .data {
          // font-family: $font-gotham-light;
          font-size: 0.844rem;
        }
      }
    }
    .header-image {
      position: absolute;
      height: auto;
      top: 4em;
      left: 0;
      padding: 2em 1em;
    }
    .modal-content{
  
      
    }
  
    .banner-referral {
      background: #535474;
      .header-row {
        padding: 2em 0em;
        height: 15em;
       
        .round-background-icon {
          background: #ffffff;
          box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
          border-radius: 50%;
          // padding: 0.3em 0.3em;
          height: 2em;
          width: 2em;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 3;
        }
        label {
          font-family: $font-gotham-medium;
          font-size: 1rem;
          color: #ffffff;
        }
      }
    }
    .referral-container {
      .referral-link {
        padding: 0.5em;
        box-shadow: 0px 14px 20px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        margin: -2em 0.5em 0.5em 0.5em;
        background: #ffffff;
        align-items: center;
        .referral-image {
          border-radius: 50%;
          width: 100%;
        }
        .link{
          font-size: 0.8em;
          color: blue;
        }
        label {
          font-family: $font-gotham-medium;
          font-size: 1rem;
          margin: 0em;
        }
      }
      .scoll-referral {
        position: relative;
        // min-height: 50vh;
        label {
          font-family: $font-gotham-medium;
          font-size: 1rem;
          margin: 1em 0em;
        }
        p {
          font-family: $font-gotham-book;
          font-size: 0.825rem;
        }
      }
    }
    .language-container {
      margin-top: 2em;
      .language-radio {
        background: #ffffff;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        border-radius: 0.5em;
        align-items: center;
        display: flex;
        height: 4em;
        margin: 1em;
        &.active{
          border: 0.2em solid #6abed2c0
  
        }
        label {
          font-family: $font-gotham-medium;
          color: #707070;
          &.active{
            color: #6abed2c0;
          }
        }
        .form-check-input[type=radio]:checked:after{
          background-color: #6abed2c0;
          border-color: #6abed2c0;
        }
      }
    }
    .setting {
      margin: 1em;
      .setting-label {
        font-size: 1.2rem;
        font-family: $font-gotham-medium;
        p {
          font-size: 0.95rem;
          // font-family: $font-gotham-light;
        }
      }
      .notifacation-radio {
        align-items: center;
        display: flex;
        margin: 1em;
        label {
          font-family: $font-gotham-book;
        }
      }
    }
    .wallet-history {
      padding: 0em 1em;
      margin-top: 0.5em;
      .wallet-list {
        align-items: center;
        height: 3.9em;
        padding: 0em 0.5em;
        label {
          font-family: $font-gotham-medium;
          font-size: 0.75rem;
        }
        span {
          font-family: $font-gotham-book;
          font-size: 0.65rem;
          color: #303345;
        }
        .wallet-money {
          text-align: end;
          .p {
            font-size: 0.9rem;
            color: $font-color;
            font-family: $font-gotham-medium;
          }
        }
        .line {
          margin-top: 0.2em;
          border: 0.5px solid #bbb6b6;
        }
      }
    }
    .no-data {
      align-items: center;
      text-align: center;
      label {
        color: #d6d6d6;
        font-family: $font-gotham-medium;
        font-size: 1.5rem;
      }
    }
  }

}

@media only screen and (min-width: 1200px) {
  .profile-modal-container{
    border-radius: 1em ;
    .header-five-container {
      border-radius: 1em 1em 0em 0em;
    }
  }
}
.qr-container {
  text-align: center;
  background: $btn-color-blueDark;
  label {
    font-family: $font-gotham-bold;
    color: $btn-color-disable;
    margin: 1em 0em;
    font-size: 1.1rem;
  }
  .btn-primary{
    margin: 1em 0.8em;
    width: auto;
    align-items: center;
    text-align: center;
    border-radius: 1em;
    background: #F5F5F5 !important;
    color: #707070 !important;
  }
  .btn-primary:active{
    margin: 1em 0.8em;
    width: auto;
    align-items: center;
    text-align: center;
    border-radius: 1em;
    background: #F5F5F5;
    color: #707070;
  }
  .btn-primary:hover{
    margin: 1em 0.8em;
    width: auto;
    align-items: center;
    text-align: center;
    border-radius: 1em;
    background: #F5F5F5;
    color: #707070;
  }
}
.full-modal-box-container-agent-container {
  .modal-body {
    font-family: $font-gotham-book;
    padding: 0;
  }
  .header-row {
    padding: 2em 0;
    .round-background-icon {
      background: #ffffff;
      box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
      border-radius: 50%;
      // padding: 0.3em 0.3em;
      height: 2em;
      width: 2em;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 3;
    }
  }
  .banner-agent-container {
    color: #ffffff;
    background-color: #405854;
    height: 22em;
    .available-container {
      text-align: center;
      .header-label {
        font-size: 0.875rem;
        border-bottom: 0.1em solid #f5f5f5;
        width: 15em;
        margin-left: auto;
        margin-right: auto;
      }
      .comission-label {
        padding: 0.5em;
        padding-bottom: 0;
        font-size: 1.5rem;
        font-weight: bold;
        label {
          justify-content: center;
          display: flex;
          align-items: start;
          font-family: $font-gotham-medium;
        }
        span {
          font-size: 1rem;
          font-weight: 100;
          padding: 0 0.5em;
        }
      }
    }
    .toggle-container {
      padding: 1.5em 1em;
      font-size: 1rem;
      justify-content: center;
      .background {
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: #19332f;
        padding: 0.3em;
        border-radius: 0.5rem;
      }
      .active {
        background-color: #56716c;
        padding: 0.2em;
        border-radius: 0.5rem;
      }
      .tab-1 {
        width: 12em;
      }
      .tab-2 {
        width: 12em;
      }
    }
  }
  .agent-content-container {
    position: relative;
    top: -5em;
    justify-content: center;
    align-items: center;
    display: grid;
  }
  .transfer-credit-container {
    .bank-info {
      background: #ffffff;
      padding: 1em;
      box-shadow: 0px 16px 25px rgba(208, 208, 208, 0.6);
      border-radius: 1em;
      .border-box {
        padding: 0.3em;
        border: 0.1em solid #707070;
        border-radius: 0.5rem;
        margin: 0.5em 2em;
        .name-label {
          text-align: center;
        }
      }
      .title {
        font-weight: bold;
        color: #303345;
      }
      .input-field {
        margin-top: 0.5em;
        border-bottom: 0.1em solid #d6d6d6;
        padding: 0.5em;
        input {
          border: none;
          outline: none;
        }
        input::placeholder {
          color: #d6d6d6;
        }
      }
      .description-label {
        font-size: 0.75rem;
        padding: 2em 0;
        // font-family: $font-gotham-light;
        label {
          color: #888daa;
          span {
            color: $btn-color-dange;
          }
        }
      }
      
    }
  }
  .wallet-detail-container {
    .bank-info {
      background: #ffffff;
      padding: 1em;
      box-shadow: 0px 16px 25px rgba(208, 208, 208, 0.6);
      border-radius: 1em;
      .border-box {
        padding: 0.3em;
        border: 0.1em solid #707070;
        border-radius: 0.5rem;
        .name-label {
          text-align: center;
          color: #707070;
          font-weight: bold;
        }
        .single-line {
          margin: 0.3em 0;
          border-bottom: 0.1em solid #d6d6d6;
        }
        .bank-label {
          // font-family: $font-gotham-light;
        }
      }
      .description-label {
        font-size: 0.75rem;
        padding: 2em 0;
        // font-family: $font-gotham-light;
        label {
          color: #888daa;
          span {
            color: $btn-color-dange;
          }
        }
      }
      .input-container {
        display: flex;
        align-items: center;
        .input-field {
          input {
            width: auto;
            font-size: 1.875rem;
            text-align: end;
            border: none;
            outline: none;
          }
        }
        .button {
          .button-container {
            justify-content: start;
            button {
              width: auto;
              background: #405854;
              opacity: 1;
              padding: 0.5rem !important;
              text-transform: capitalize;
              font-size: 14px;
              box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
            }
          }
        }
      }
    }
  }
  .detail-container {
    margin: 2em 0;
    .single-line {
      margin: 1.3em 0;
      border-bottom: 0.1em solid #d6d6d6;
    }
    .listing-row {
      font-size: x-small;
      .title {
        margin-bottom: 0.5em;
        label {
          color: #303345;
          font-size: 0.75rem;
          font-weight: bold;
        }
      }
      .detail {
        line-height: 1.1em;
      }
      .price {
        &.bonus {
          color: #41b1cd;
        }
        &.normal {
          color: #707070;
        }
        font-weight: bold;
        font-size: 0.875rem;
        display: flex;
        justify-content: start;
        align-items: center;
      }
    }
  }
}

.full-modal-box-container-wallet-container {
  z-index: 9999 !important;
  .modal-body {
    font-family: $font-gotham-book;
    padding: 0;
  }
  .header-row {
    padding: 2em 0;
    .round-background-icon {
      background: #ffffff;
      box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
      border-radius: 50%;
      // padding: 0.3em 0.3em;
      height: 2em;
      width: 2em;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 3;
    }
  }
  .banner-agent-container {
    color: #ffffff;
    background-color: $btn-color-blueDark;
    height: 22em;
    .available-container {
      text-align: center;
      .header-label {
        font-size: 0.875rem;
        border-bottom: 0.1em solid #f5f5f5;
        width: 15em;
        margin-left: auto;
        margin-right: auto;
      }
      .comission-label {
        padding: 0.5em;
        padding-bottom: 0;
        font-size: 1.5rem;
        font-weight: bold;
        label {
          justify-content: center;
          display: flex;
          align-items: start;
        }
        span {
          font-size: 1rem;
          font-weight: 100;
          padding: 0 0.5em;
        }
      }
    }
    .toggle-container {
      padding: 1.5em 1em;
      font-size: 1rem;
      .background {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: #19332f;
        padding: 0.3em;
        border-radius: 0.5rem;
      }
      .active {
        background-color: #56716c;
        padding: 0.2em;
        border-radius: 0.5rem;
      }
      .tab-1 {
        width: 12em;
      }
      .tab-2 {
        width: 12em;
      }
    }
  }
  .agent-content-container {
    position: relative;
    top: -5em;
  }
  .transfer-credit-container {
    .bank-info {
      background: #ffffff;
      padding: 1em;
      box-shadow: 0px 16px 25px rgba(208, 208, 208, 0.6);
      border-radius: 1em;
      .border-box {
        padding: 0.3em;
        border: 0.1em solid #707070;
        border-radius: 0.5rem;
        margin: 0.5em 2em;
        .name-label {
          text-align: center;
        }
      }
      .title {
        font-weight: bold;
        color: #303345;
      }
      .input-field {
        margin-top: 0.5em;
        border-bottom: 0.1em solid #d6d6d6;
        padding: 0.5em;
        input {
          border: none;
          outline: none;
        }
        input::placeholder {
          color: #d6d6d6;
        }
      }
      .description-label {
        font-size: 0.75rem;
        padding: 2em 0;
        // font-family: $font-gotham-light;
        label {
          color: #888daa;
          span {
            color: $btn-color-dange;
          }
        }
      }
    }
  }
  .wallet-detail-container {
    .bank-info {
      background: #ffffff;
      padding: 1em;
      box-shadow: 0px 16px 25px rgba(208, 208, 208, 0.6);
      border-radius: 1em;
      margin-top: -10em;
      .btn-primary {
        color: black;
        background: white;
        width: 100%;
        height: auto;
        font-family: $font-gotham-book;
        font-size: 1.1rem;
        margin: 0.7em 0em;
      }
      .btn-check:checked + .btn-primary {
        color: white;
        background: $btn-color-blueLight;
        width: 100%;
        font-family: $font-gotham-book;
        margin: 0.7em 0em;
        height: auto;
        font-size: 1.1rem;
      }
      .border-box {
        padding: 0.3em;
        border: 0.1em solid #707070;
        border-radius: 0.5rem;
        .name-label {
          text-align: center;
          color: #707070;
          font-weight: bold;
        }
        .single-line {
          margin: 0.3em 0;
          border-bottom: 0.1em solid #d6d6d6;
        }
        .bank-label {
          // font-family: $font-gotham-light;
        }
      }
      .description-label {
        font-size: 0.75rem;
        padding: 2em 0;
        // font-family: $font-gotham-light;
        label {
          color: #888daa;
          span {
            color: $btn-color-dange;
          }
        }
      }
      .input-container {
        display: flex;
        align-items: center;

        .input-field {
          text-align: end;
          .wallet-topup {
            // width: auto;
            font-size: 1.875rem;
            border: none;
            outline: none;
            padding: 0;
          }
          input {
            width: 3.7em;
            font-size: 1.875rem;
            text-align: start;
            border: none;
            outline: none;
          }
        }
        .button {
          .button-container {
            justify-content: start;
            button {
              width: auto;
              background: $btn-color-blueLight;
              opacity: 1;
              color: $btn-color-disable;
              padding: 0.5rem !important;
              text-transform: capitalize;
              font-size: 14px;
              margin-left: 0.7em;
              box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
            }
          }
        }
      }
      .text{
        color: #707070;
        font-size:0.7em ;
        text-align: end;
      }
    }
  }
  .detail-container {
    margin: 2em 0;
    .single-line {
      margin: 1.3em 0;
      border-bottom: 0.1em solid #d6d6d6;
    }
    .listing-row {
      font-size: x-small;
      .title {
        margin-bottom: 0.5em;
        label {
          color: #303345;
          font-size: 0.75rem;
          font-weight: bold;
        }
      }
      .detail {
        line-height: 1.1em;
      }
      .price {
        &.bonus {
          color: #41b1cd;
        }
        &.normal {
          color: #707070;
        }
        ._span{
          &.--amount{
            font-weight: bold;
            font-size: 0.875rem;
            display: flex;
            justify-content: end;
            align-items: center;
          }
          &.--description{
            font-size: 0.68rem;
            display: flex;
            justify-content: end;
            align-items: center;
          }
        }
      }
    }
  }
}

.full-modal-box-container-qr-container {
  z-index: 99999;

 .modal-dialog{
   .modal-content{
     .modal-body{
       border-radius: 0.5em;
     }
  }
 }
 
}

.full-modal-box-container-bank {
  z-index: 99999;
  .modal-content {
    border-radius: 0.5rem !important;
    padding: 1em;
    .contact-formik-form {
      height: 70vh !important;
    }
    .button-container {
      position: absolute;
      bottom: 0;
      width: 100%;
      button {
        background-color: $btn-color-blueDark;
      }
    }
  }
}
.copy-container{
  position: fixed;
  height: 100vh;
  width: 100%;
  bottom: 0em;
  left: 0;
  transform: translate(0%,85%);
  .copy-background{
    background-color: rgba(0, 0, 0, 0.5);
    color: #f5f5f5;
    padding: 0.5em;
    width: 10em;
    text-align: center;
    border-radius: 0.5em;
    margin-left: auto;
    margin-right: auto;
  }
}

/*!****************
    Font Family
******************/
@font-face {
  font-family: "gothamLight";
  
  // src: url(./assets/fonts/Gotham-Light.otf);
  src: url(./assets/fonts/GothamBook.otf);
}

@font-face {
  font-family: "gothamBook";
  src: url(./assets/fonts/GothamBook.otf);
}

@font-face {
  font-family: "gothamMedium";
  line-height: 24px;
  src: url(./assets/fonts/GothamMedium.otf);
}

@font-face {
  font-family: "gothamBold";
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  src: url(./assets/fonts/Gotham-Bold.otf);
}

@font-face {
  font-family: "MerriweatherBold";
  src: url(./assets/fonts/Merriweather-Bold.ttf);
}

@font-face {
  font-family: "password";
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/password.ttf);
}

/*!****************
  Font Family END
******************/
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}

/*!****************
    BottomSheet
******************/

:root {
  --rsbs-backdrop-bg: rgba(0, 0, 0, 0.6);
  --rsbs-bg: #fff;
  --rsbs-handle-bg: hsla(0, 0%, 0%, 0.14);
  --rsbs-max-w: auto;
  --rsbs-ml: env(safe-area-inset-left);
  --rsbs-mr: env(safe-area-inset-right);
  --rsbs-overlay-rounded: 16px;
}

[data-rsbs-footer] {
  padding: 0 !important;
}

/*!****************
  BottomSheet END
******************/

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
